import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FarmDto } from "@apiModels/farmDto"; // Adjust this import as needed
import { CustomerService } from "@globals/services/customer.service";

@Component({
  selector: "app-farm-edit-dialog",
  templateUrl: "./farm-edit-dialog.component.html",
  styleUrls: ["./farm-edit-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FarmEditDialogComponent implements OnInit {
  @Input() visible = false;
  @Input() farm: FarmDto | null = null;
  @Output() onSave = new EventEmitter<FarmDto>();
  @Output() onClose = new EventEmitter<void>();

  farmName = "";
  farmAddress = "";
  farmPhone = "";
  farmEmail = "";
  farmInfo = "";
  farmChrNr: number | null = null;
  farmBesNr: number | null = null;

  constructor(
    private customerService: CustomerService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.farm) {
      this.initializeFarmFields();
    }
  }

  ngOnChanges(): void {
    if (this.farm) {
      this.initializeFarmFields();
    }
  }

  initializeFarmFields(): void {
    this.farmName = this.farm?.name || "";
    this.farmAddress = this.farm?.address || "";
    this.farmPhone = this.farm?.phone || "";
    this.farmEmail = this.farm?.email || "";
    this.farmInfo = this.farm?.info || "";
    this.farmChrNr = this.farm?.chrNr || null;
    this.farmBesNr = this.farm?.besNr || null;
  }

  saveFarm(): void {
    const updatedFarm: FarmDto = {
      ...this.farm,
      name: this.farmName,
      address: this.farmAddress,
      phone: this.farmPhone,
      email: this.farmEmail,
      info: this.farmInfo,
      chrNr: this.farmChrNr,
      besNr: this.farmBesNr
    };

    this.customerService.saveFarm(updatedFarm).subscribe({
      next: () => {
        this.visible = false;
        this.cd.detectChanges();
        this.onSave.emit(updatedFarm);
        this.onClose.emit();
      },
      error: error => {
        console.error("Error saving farm", error);
      }
    });
  }

  closeDialog(): void {
    this.visible = false;
    this.onClose.emit();
  }
}
