import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, ValidationErrors } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { HubspotCompanyDto } from "@apiModels/hubspotCompanyDto";
import { BiDomService } from "@core/utility-services/bi-dom.service";
import { BiLocalizationHelperService } from "@core/utility-services/bi-localization-helper.service";
import { CustomValidators } from "@globals/classes/custom-validators";
import { ActivityDtoExt } from "@globals/ExtModels/ActivityDtoExt";
import { CustomerService } from "@globals/services/customer.service";
import { HubspotService } from "@globals/services/hubspot.service";
import { UserService } from "@globals/services/user.service";
import { UntilDestroy } from "@ngneat/until-destroy";
import { statusFilterItems, userItems } from "@shared/interfaces-and-enums/shared-data";
import { TableColumnPrimeNgExt } from "@shared/interfaces-and-enums/TableColumnPrimeNgExt";
import { WindowSessionStorageNames } from "@shared/variables-and-functions/WindowSessionStorageNames";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { Observable, ReplaySubject } from "rxjs";

@UntilDestroy()
@Component({
  selector: "app-prospect-detail-master-data",
  templateUrl: "./prospect-detail-master-data.component.html",
  styleUrls: ["./prospect-detail-master-data.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService, ConfirmationService]
})
export class ProspectDetailMasterDataComponent implements OnInit {
  @Input() hubspotCompanyId: number;
  @Output() closeDialog = new EventEmitter<HubspotCompanyDto | null>();

  // Assuming you have a method to save the data
  // saveAndClose(company: HubspotCompanyDto) {
  //   // Perform the save operation here...
  //   this.closeDialog.emit(company);
  // }

  close(company: HubspotCompanyDto) {
    this.closeDialog.emit(company);
  }

  public mainForm: FormGroup;
  public showFormErrorMessage: boolean = false;
  public pageTitle = "Prospoect redigering";
  public loading = false;
  public errorMessage = "";
  public selectedCompany: HubspotCompanyDto;
  public farmUserItem: SelectItem;

  public farmLeadStatusItem: SelectItem;

  public userId = +window.sessionStorage.getItem(WindowSessionStorageNames.userId);

  commands: any[];

  // public itemList: Array<ActivityDtoExt> = [];
  // public itemList$: Observable<Array<ActivityDtoExt>>;

  // private columns = new ReplaySubject<Array<TableColumnPrimeNgExt>>(1);
  // public columns$ = this.columns.asObservable();

  // private globalFilterFields = new ReplaySubject<Array<string>>(1);
  // public globalFilterFields$ = this.globalFilterFields.asObservable();

  // public selectedItem: ActivityDtoExt | null = null;

  // public dialogVisible = false;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private messageService: MessageService,
    private hubspotService: HubspotService,
    private confirmationService: ConfirmationService
  ) {
    // this.initColumns();
  }

  action1() {
    console.log("Action 1");
  }

  action2() {
    console.log("Action 2");
  }

  action3() {
    console.log("Action 3");
  }

  ngOnInit() {
    if (this.hubspotCompanyId) {
      this.hubspotService.getHubspotCompanyDtoFromId(this.hubspotCompanyId).subscribe(
        (data: HubspotCompanyDto) => {
          this.selectedCompany = data;

          this.farmUserItem = this.userItems.find(user => user.value === this.selectedCompany.ansvarligBrugerId);
          this.farmLeadStatusItem = this.statusFilterItems.find(stage => stage.value === this.selectedCompany.status);
          //this.cd.detectChanges();
          this.initFormGroup();
          //this.cd.markForCheck();
        },
        error => {
          console.error("Error fetching company data", error);
          this.errorMessage = "Error fetching company data";
        }
      );
    } else {
      console.error("Selected farm ID is missing");
      this.errorMessage = "Selected farm ID is missing";
    }
  }

  private initFormGroup() {
    this.mainForm = new FormGroup({
      contactName: new FormControl(this.selectedCompany.contactName),
      contactEmail: new FormControl(this.selectedCompany.contactEmail, CustomValidators.email()),
      contactPhone: new FormControl(this.selectedCompany.contactPhone),
      status: new FormControl(this.selectedCompany.status),
      ansvarligBrugerId: new FormControl(this.selectedCompany.ansvarligBrugerId),
      race: new FormControl(this.selectedCompany.race),
      mejerier: new FormControl(this.selectedCompany.mejerier),
      noter: new FormControl(this.selectedCompany.noter)
    });

    setTimeout(() => {
      this.mainForm.markAsPristine();
      // this.initializeCommands();
      // this.updateCommandStates();
      this.cd.detectChanges();
    }, 100);

    //this.mainForm.reset(this.mainForm.value);
  }

  private initializeCommands() {
    this.commands = [
      {
        label: "Opret som kunde",
        // icon: "pi pi-refresh",
        command: () => {
          this.confirmCreateCustomer();
        },
        disabled: this.mainForm.pristine || this.selectedCompany?.customerId
      },
      {
        label: "Action 2",
        icon: "pi pi-times",
        command: () => {
          this.action2();
        },
        disabled: false
      },
      {
        label: "Action 3",
        icon: "pi pi-info",
        command: () => {
          this.action3();
        },
        disabled: false
      }
    ];
  }

  private updateCommandStates() {
    if (this.selectedCompany) {
      this.commands.forEach(command => {
        // Example logic to enable/disable commands
        if (command.label === "Action 1") {
          command.disabled = this.selectedCompany.status !== "Active";
        }
        if (command.label === "Action 2") {
          command.disabled = this.selectedCompany.status === "Inactive";
        }
        // Additional logic for other commands
      });
    }
  }

  public userItems = userItems;

  public statusFilterItems = statusFilterItems;

  public mejeriItems: Array<SelectItem> = [
    { value: null, label: "Ingen" },
    { value: "Naturmælk", label: "Naturmælk" },
    { value: "Thise", label: "Thise" }
  ];

  public get contactName() {
    return this.mainForm.get("contactName");
  }
  public get contactEmail() {
    return this.mainForm.get("contactEmail");
  }
  public get contactPhone() {
    return this.mainForm.get("contactPhone");
  }

  public get status() {
    return this.mainForm.get("status");
  }

  public get ansvarligBrugerId() {
    return this.mainForm.get("ansvarligBrugerId");
  }

  public get mejerier() {
    return this.mainForm.get("mejerier");
  }

  public get race() {
    return this.mainForm.get("race");
  }

  public get noter() {
    return this.mainForm.get("noter");
  }

  private checkAndValidateForm(): boolean {
    if (this.mainForm.invalid) {
      Object.keys(this.mainForm.controls).forEach(cName => this.mainForm.controls[cName].markAsTouched());
      this.showFormErrorMessage = true;
      return false;
    }
    this.showFormErrorMessage = false;
    return true;
  }

  public getFormValidationErrors() {
    console.log("%c ==>> Validation Errors: ", "color: red; font-weight: bold; font-size:25px;");
    let totalErrors = 0;
    Object.keys(this.mainForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.mainForm.get(key).errors;
      if (controlErrors != null) {
        totalErrors++;
        Object.keys(controlErrors).forEach(keyError => {
          console.log("Key control: " + key + ", keyError: " + keyError + ", err value: ", controlErrors[keyError]);
        });
      }
    });
    console.log("Number of errors: ", totalErrors);
  }

  public saveItem() {
    if (!this.checkAndValidateForm()) return;

    //this.selectedCompany.byNavn = this.byNavn.value;

    Object.keys(this.mainForm.controls).forEach(key => {
      const controlValue = this.mainForm.get(key).value;

      // Check if the control value is an object (dropdown selected item)
      if (controlValue && typeof controlValue === "object" && controlValue.hasOwnProperty("value")) {
        this.selectedCompany[key] = controlValue.value;
      } else {
        this.selectedCompany[key] = controlValue;
      }
    });

    // this.selectedCompany.ansvarligBrugerId = this.ansvarligBrugerId.value;

    // this.selectedCompany.status = this.status.value;

    this.hubspotService.updateHubspotCompany(this.selectedCompany).subscribe({
      next: () => {
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Data blev gemt"
        });

        //        alert("Data blev gemt");
        //this.initFormGroup();

        ///this.mainForm.reset();
      },
      error: err => {
        console.error("Error saving company data", err);
        this.errorMessage = "Error saving company data";
      }
    });
  }

  confirmCreateCustomer() {
    this.confirmationService.confirm({
      message: `Er du sikker på, at du vil oprette [<b> ${this.selectedCompany.brugerNavn}</b>] som kunde?`,
      header: "Bekræftelse",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Ja",
      rejectLabel: "Nej",
      acceptButtonStyleClass: "p-button-primary",
      rejectButtonStyleClass: "p-button-secondary",
      accept: () => {
        this.createCustomer();
      }
    });
  }

  public createCustomer() {
    this.hubspotService.createCustomer(this.selectedCompany).subscribe(customerId => {
      this.router.navigate(["/superadmin/customers", customerId, "main"]);
    });
  }

  public openInNamedWindow(event, windowName) {
    event.preventDefault(); // Forhindrer standard linkadfærd
    // Tjekker om det navngivne vindue allerede eksisterer
    if (windowName && windowName in window && !window[windowName].closed) {
      window[windowName].location.href = event.target.href; // Åbn linket i det navngivne vindue
    } else {
      window.open(event.target.href, windowName, "width=600,height=400"); // Åbn linket i et nyt vindue med det navngivne navn
    }
  }

  public createMail() {
    this.hubspotService.createMail(this.hubspotCompanyId).subscribe(messageId => {
      let encodedMailId = encodeURIComponent(messageId.messageId);

      encodedMailId = encodedMailId.replace(/_/g, "%2B");

      const url = `https://outlook.office.com/mail/compose/${encodedMailId}`;

      console.log(url);

      window.open(url, "mailWindow");
    });
  }

  public createAppointment() {
    this.hubspotService.createAppointment(this.hubspotCompanyId).subscribe(calendarId => {
      let encodedEventId = encodeURIComponent(calendarId.calendarId);

      encodedEventId = encodedEventId.replace(/_/g, "%2B");

      const url = `https://outlook.office.com/calendar/item/${encodedEventId}`;

      https: console.log(url);

      window.open(url, "mailWindow");
    });
  }

  // private initColumns() {
  //   this.globalFilterFields.next(["title", "description"]);

  //   this.columns.next([
  //     { field: "title", header: "Titel" },
  //     { field: "activityType", header: "Type" },
  //     { field: "category", header: "Kategori" },
  //     { field: "place", header: "Sted" },
  //     { field: "assignedUsersString", header: "Tildelte brugere" },
  //     { field: "descriptionShort", header: "Beskrivelse", sortField: "description", toolTip: "description" },
  //     { field: "weekNumber", header: "Uge" },
  //     { field: "datePlannedStart", header: "Planlagt start" },
  //     // { field: "planedDurationMinutes", header: "Varighed i minutter" },
  //     { field: "datePlannedEnd", header: "Planlagt slut" },
  //     { field: "isReminderEnabledText", header: "Påmindelse" },
  //     { field: "dateCreated", header: "Oprettet", sortField: "dateCreatedForSort" },
  //     // { field: "dateModified", header: "Ændringsdato", sortField: "dateReminderForSort" },
  //     { field: "dateCompleted", header: "Færdiggjort", sortField: "dateCompletedForSort" },
  //     { field: "dateReminder", header: "Reminder", sortField: "dateReminderForSort" },
  //     { field: "dateCompletedByUser", header: "Færdiggjort af bruger" }
  //     // { field: "dateReminder", header: "Påmindelsesdato", sortField: "dateReminderForSort" }
  //   ]);
  // }

  // onRowSelect(event) {
  //   console.log("onRowSelect", event);
  //   this.selectedItem = { ...event.data }; // Deep copy to prevent direct mutation
  //   this.dialogVisible = true;
  //   this.cd.detectChanges();
  // }

  // public downloadExcelBenefitCalculation() {
  //   this.cd.detectChanges();
  //   this.hubspotService
  //     .downloadExcelBenefitCalculation(this.hubspotCompanyId)
  //     .pipe(take(1))
  //     .subscribe(data => {
  //       this.domService.downloadFile(data, "BenefitCalc" + this.hubspotCompanyId + ".xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
  //     });
  // }
}
