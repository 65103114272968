<div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
  <div class="text-center mb-5">
    <div class="text-900 text-3xl font-medium mb-3">Nulstill dit password</div>
  </div>

  <form [formGroup]="loginForm" id="login-form">
    <div>
      <label class="block text-900 font-medium mb-2"
        >E-mail : {{ this.userEmail }}</label
      >
      <label class="block text-900 font-medium mb-2"
        >Enter the desired password in the fields below:</label
      >
      <label for="password1" class="block text-900 font-medium mb-2"
        >New password</label
      >
      <input
        #input
        id="newPassword"
        pInputText
        class="w-full mb-3"
        formControlName="newPassword"
        [pTooltip]="passwordFormatHelperHtml"
        [escape]="false"
        tooltipEvent="focus"
        [tooltipPosition]="tooltipPosition"
        autocomplete="current-password"
      />

      <label for="password2" class="block text-900 font-medium mb-2"
        >Confirm Password</label
      >
      <input
        #input
        id="confirmPassword"
        pInputText
        class="w-full mb-3"
        formControlName="confirmPassword"
      />

      <button
        pButton
        pRipple
        icon="pi pi-user"
        class="w-full"
        [disabled]="loginForm.invalid || isLoading"
        [label]="'shared.Save' | translate"
        (click)="login()"
      ></button
      >{{ loginForm.invalid }} {{ newPassword.errors }}
      {{ confirmPassword.invalid }}
      <p
        *ngIf="invalidLogin"
        class="alert alert-danger"
        innerHTML="{{ invalidLogin }}"
      ></p>
    </div>
  </form>
</div>
