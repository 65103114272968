<div
  class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
>
  <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 80%">
    <div class="col-12 mt-5 xl:mt-0 text-center">
      <img
        src="assets/layout/images/logo-dark.svg"
        alt="Sakai logo"
        class="mb-5"
        style="width: 81px; height: 60px"
      />
    </div>
    <div
      class="col-12 xl:col-6"
      style="
        border-radius: 56px;
        padding: 0.3rem;
        background: linear-gradient(
          180deg,
          var(--primary-color) 10%,
          rgba(33, 150, 243, 0) 30%
        );
      "
    >
      <div
        class="h-full w-full m-0 py-7 px-4"
        style="
          border-radius: 53px;
          background: linear-gradient(
            180deg,
            var(--surface-50) 38.9%,
            var(--surface-0)
          );
        "
      >
        <div class="text-center mb-5">
          <div class="text-900 text-3xl font-medium mb-3">
            Forgot your password?
          </div>
          <span class="text-600 font-medium"
            >Enter your email to recive a reset e-mail</span
          >
        </div>

        <div class="w-full md:w-10 mx-auto">
          <form [formGroup]="mainForm" id="login-form">
            <div>
              <label for="email1" class="block text-900 font-medium mb-2"
                >Email</label
              >
              <input
                id="email1"
                type="text"
                pInputText
                class="w-full mb-3"
                type="email"
                [placeholder]="'shared.EnterEmail' | translate"
                (keyup.enter)="login()"
                formControlName="loginEmail"
                autocomplete="email"
                (change)="email.setValue($any($event.target).value)"
              />

              <p
                class="field-error"
                *ngIf="email.errors?.email && email.touched"
                translate
              >
                errorMessages.emailInvalid
              </p>
              <p
                class="field-error"
                *ngIf="email.errors?.required && email.touched"
                translate
              >
                errorMessages.MustBeFilled
              </p>

              {{ mainForm.invalid }} {{ email.errors }}

              <div class="grid formgrid">
                <div class="col-12 mb-2 lg:col-6 lg:mb-0">
                  <button
                    pButton
                    pRipple
                    icon="pi pi-user"
                    class="w-full"
                    [disabled]="mainForm.invalid"
                    [label]="'login.ResetPassword' | translate"
                    (click)="login()"
                  ></button>
                </div>

                <div class="col-12 mb-2 lg:col-6 lg:mb-0">
                  <button
                    pButton
                    pRipple
                    class="w-full"
                    [label]="'shared.Cancel' | translate"
                    [routerLink]="['/login']"
                  ></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
