import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@shared/shared.module";
import { ButtonModule } from "primeng/button";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { DialogModule } from "primeng/dialog";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { TableModule } from "primeng/table";
import { TabMenuModule } from "primeng/tabmenu";
import { ToastModule } from "primeng/toast";
import { ToolbarModule } from "primeng/toolbar";
import { UserEditGuard } from "./user-edit.guard";
import { UserEditMainComponent } from "./user-edit/user-edit-main.component";
import { UserEditComponent } from "./user-edit/user-edit.component";
import { PasswordModule } from "primeng/password";

const primeNgModules = [
  DialogModule,
  ConfirmDialogModule,
  ConfirmPopupModule,
  ToastModule,
  ToolbarModule,
  TableModule,
  InputTextareaModule,
  InputTextModule,
  ButtonModule,
  TabMenuModule,
  PasswordModule
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: ":id",
        component: UserEditComponent
      },
      {
        path: ":id/edit",
        component: UserEditComponent,
        canDeactivate: [UserEditGuard],
        children: [{ path: "", component: UserEditMainComponent }]
      }
    ]),
    ...primeNgModules
  ],
  declarations: [UserEditComponent, UserEditMainComponent]
})
export class UserModule {}
