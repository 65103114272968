import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { BiLocalizationHelperService } from "@core/utility-services/bi-localization-helper.service";
import { WikiDocumentDtoExt } from "@globals/ExtModels/WikiDocumentDtoExt";
import { CustomerService } from "@globals/services/customer.service";
import { HubspotService } from "@globals/services/hubspot.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TableColumnPrimeNgExt } from "@shared/interfaces-and-enums/TableColumnPrimeNgExt";
import { PrimeNgTableColumn, PrimeNgUtilities } from "@shared/variables-and-functions/primeNg-utilities";
import moment from "moment";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { Table } from "primeng/table";
import { Observable, ReplaySubject, finalize, map, tap } from "rxjs";

@UntilDestroy()
@Component({
  templateUrl: "./wiki-list.component.html",
  styleUrls: ["./wiki-list.component.scss"],
  providers: [MessageService, ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WikiListComponent implements OnInit {
  @ViewChild("table") table: Table;
  @ViewChild("wikiForm") wikiForm: NgForm;

  public loading = true;

  public itemList: Array<WikiDocumentDtoExt> = [];
  public itemList$: Observable<Array<WikiDocumentDtoExt>>;
  private columns = new ReplaySubject<Array<TableColumnPrimeNgExt>>(1);
  public columns$ = this.columns.asObservable();
  private globalFilterFields = new ReplaySubject<Array<string>>(1);
  public globalFilterFields$ = this.globalFilterFields.asObservable();
  selectedValue: string;

  public selectedItem: WikiDocumentDtoExt | null = null;

  public dialogVisible = false;
  public sortField = "dateCreatedForSort";
  public sortOrder = -1;

  public wikiRevisionFilterItems: Array<SelectItem> = [
    { value: "Seneste", label: "Seneste" },
    { value: "Alle", label: "Alle" }
  ];

  public selectedWikiRevisionItem = this.wikiRevisionFilterItems.find(obj => {
    return obj.value === this.customerService.wikiRevisionFilter;
  });

  constructor(
    public hubspotService: HubspotService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    private localizor: BiLocalizationHelperService,
    private sanitizer: DomSanitizer,
    private customerService: CustomerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.initColumns();
    this.initializeItems();
  }

  private initColumns() {
    this.globalFilterFields.next(["content", "title", "user"]);
    this.columns.next([
      { field: "title", header: "Titel" },
      { field: "contentShort", header: "Indhold", sortField: "content", toolTip: "content" },
      { field: "user", header: "Bruger" },
      { field: "dateCreated", header: "Oprettet", sortField: "dateCreatedForSort" },
      { field: "revision", header: "Revision" }
    ]);
  }

  public selectedTilbudRevisionItemChange() {
    this.customerService.wikiRevisionFilter = this.selectedWikiRevisionItem.value;
    this.initializeItems();
  }

  onRowSelect(event) {
    this.selectedItem = { ...event.data }; // Deep copy to prevent direct mutation of the selected item
    this.dialogVisible = true;
    this.cd.detectChanges();
  }

  handleCloseDialog() {
    this.dialogVisible = false;
    this.selectedItem = null;
    this.initializeItems();
  }

  public initializeItems() {
    this.itemList$ = this.customerService.getWikiDocuments(this.selectedWikiRevisionItem.value).pipe(
      tap((data: Array<WikiDocumentDtoExt>) => {
        data.forEach(element => {
          element.dateCreated = this.localizor.localizeDateTime(element.dateCreatedUtc);
          element.dateCreatedForSort = moment(element.dateCreatedUtc);
          if (element.content) {
            element.contentShort = element.content.length > 100 ? element.content.substring(0, 97) + "..." : element.content;
          } else {
            element.contentShort = element.content;
          }
        });
      }),
      untilDestroyed(this),
      finalize(() => (this.loading = false))
    );
  }

  saveChanges() {
    if (this.selectedItem) {
      this.customerService.saveWikiDocument(this.selectedItem).subscribe({
        next: () => {
          this.messageService.add({ severity: "success", summary: "Success", detail: "Changes saved successfully." });
          this.dialogVisible = false;
          this.selectedItem = null;
          this.initializeItems();
        },
        error: error => {
          this.messageService.add({ severity: "error", summary: "Error", detail: "Failed to save changes." });
          console.error("Error saving changes:", error);
        }
      });
    }
  }

  deleteItem() {
    this.confirmationService.confirm({
      message: "Ønsker du at slette " + this.selectedItem.title + " ?",
      header: "Bekræft sletning",
      icon: "pi pi-info-circle",
      acceptButtonStyleClass: "p-button-danger p-button-text",
      rejectButtonStyleClass: "p-button-text p-button-text",
      acceptIcon: "none",
      rejectIcon: "none",
      acceptLabel: "Ja",
      rejectLabel: "Nej",
      accept: () => {
        this.selectedItem.dateDeletedUtc = new Date().toISOString();
        this.saveChanges();
      },
      reject: () => {
        // Do nothing or handle rejection
      }
    });
  }

  onCreateNewWiki() {
    this.selectedItem = {
      id: 0,
      wikiId: 0,
      revision: 0,
      title: "",
      content: "",
      createdByUserId: 0,
      dateCreatedUtc: new Date().toISOString(),
      dateCreated: "",
      dateCreatedForSort: moment(),
      contentShort: ""
    };
    this.dialogVisible = true;

    setTimeout(() => {
      if (this.wikiForm) {
        this.wikiForm.controls["title"].markAsTouched();
        this.wikiForm.controls["content"].markAsTouched();
      }
    }, 0);
  }

  public exportToExcel() {
    this.columns$.pipe(map(columnsArray => columnsArray.filter(col => !col.noExport))).subscribe(exportableColumns => {
      PrimeNgUtilities.exportCSV(this.table, exportableColumns as PrimeNgTableColumn[], { bom: true });
    });
  }

}
