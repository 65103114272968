import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { EmailAttachmentModel } from "@apiModels/emailAttachmentModel";
import { EmailModel } from "@apiModels/emailModel";
import { BiDomService } from "@core/utility-services/bi-dom.service";
import { BiLocalizationHelperService } from "@core/utility-services/bi-localization-helper.service";
import { BiCountryId } from "@enums/BiLanguageAndCountryId";
import { EmailModelExt } from "@globals/ExtModels/EmailModelExt";
import { CustomerService } from "@globals/services/customer.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TableColumnPrimeNgExt } from "@shared/interfaces-and-enums/TableColumnPrimeNgExt";
import { PrimeNgUtilities } from "@shared/variables-and-functions/primeNg-utilities";
import moment from "moment";
import { ConfirmationService, MessageService, SelectItem, SortEvent } from "primeng/api";
import { Table } from "primeng/table";
import { finalize, map, Observable, ReplaySubject, take, tap } from "rxjs";

@UntilDestroy()
@Component({
  templateUrl: "superadmin-emaillist.component.html",
  styleUrls: ["superadmin-emaillist.component.scss"],
  providers: [MessageService, ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuperAdminEmailListComponent implements OnInit {
  @ViewChild("table") table: Table;

  public loading = true;

  public emails: Array<EmailModel> = [];
  public emails$: Observable<Array<EmailModel>>;
  private columns = new ReplaySubject<Array<TableColumnPrimeNgExt>>(1);
  public columns$ = this.columns.asObservable();
  private globalFilterFields = new ReplaySubject<Array<string>>(1);
  public globalFilterFields$ = this.globalFilterFields.asObservable();
  public errorMessage = "";
  public src: Blob;
  public pdfViewerVisible = false;
  public pdfSpinner = false;
  public displayPdfDialog: boolean;

  public countries: Array<SelectItem> = [
    { value: BiCountryId.DK, label: "Danmark" },
    { value: BiCountryId.SE, label: "Sverige" },
    { value: BiCountryId.FI, label: "Finland" },
    { value: BiCountryId.NO, label: "Norge" }
  ];

  public selectedCountryCode = this.countries[0];
  public fromDate: Date = new Date();
  public toDate: Date = new Date();

  selectedValue: string;

  private countryId = BiCountryId.DK;

  selectedEmail: EmailModel;
  attachments: EmailAttachmentModel[] = [];

  cols: any[];

  showDeleted: boolean;

  displayEmailDialog: boolean;
  displayEmailAttachmentsDialog: boolean;

  text: string;

  showEmail() {
    this.displayEmailDialog = true;
  }

  sendEmails() {
    this.customerService.sendUnsentMails().subscribe({
      next: () => {
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Data blev genskabt"
        });

        this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => this.router.navigate(["/superadmin/customers/emails"]));
      },
      error: err => (this.errorMessage = err)
    });
  }

  constructor(
    private customerService: CustomerService,
    private messageService: MessageService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private localizor: BiLocalizationHelperService,
    private cd: ChangeDetectorRef,
    private domService: BiDomService
  ) {}

  ngOnInit() {
    this.text = "<h2>Vælg en email i listen for at se indholdet</h2>";

    this.fromDate.setDate(new Date().getDate() - 30);

    this.initializeEmails();

    this.initColumns();
  }

  onRowSelect(event) {
    // this.text = this.selectedEmail.body.substring(this.selectedEmail.body.lastIndexOf("<!--contentstart -->") + 20, this.selectedEmail.body.lastIndexOf("<!--contentend -->"));
    this.text = this.selectedEmail.body;
    this.showEmail();
  }

  showAttachments(email: EmailModel) {
    this.selectedEmail = email;

    this.customerService.getEmailAttachments(this.selectedEmail.id).subscribe(
      (data: Array<EmailAttachmentModel>) => {
        this.attachments = data;
      },
      error => {
        console.error("Failed to retrieve attachments:", error);
        this.attachments = [];
      },
      () => {
        this.loading = false;
        this.displayEmailAttachmentsDialog = true;
      }
    );
  }

  showAttachment(id: number, filename: string) {
    if (filename.toLowerCase().endsWith(".pdf")) {
      this.pdfSpinner = true;
      this.customerService.getEmailAttachmentById(id).subscribe(res => {
        this.src = res;
        this.pdfSpinner = false;
        this.showPdfDialog();
      });
    } else {
      this.customerService
        .downloadEmailAttachmentById(id)
        .pipe(take(1))
        .subscribe(data => {
          this.domService.downloadFile(data, filename, "application/msword");
        });
    }
  }

  showPdfDialog() {
    this.displayPdfDialog = true;
    setTimeout(() => {
      this.pdfViewerVisible = true;
      this.cd.detectChanges();
    }, 300);
  }

  public onCreateNewEmail() {
    this.router.navigate([0, "main"], { relativeTo: this.activeRoute });
  }

  public selectedCountryCodeChange(item: SelectItem) {
    this.countryId = +item.value;
    this.initializeEmails();
  }

  public onDateFilterChanged() {
    this.initializeEmails();
  }

  public onShowActiveChange(checked: boolean) {
    if (checked) {
      this.showDeleted = true;
      this.initializeEmails();
    } else {
      this.showDeleted = false;
      this.initializeEmails();
    }
  }

  private initColumns() {
    this.globalFilterFields.next(["subject", "body", "toName", "toEmail"]);
    this.columns.next([
      { field: "dateSent", header: "Sendt", sortField: "dateSentForSort" },
      { field: "customerName", header: "Kunde" },
      { field: "subject", header: "Emne" },
      { field: "toName", header: "Til navn" },
      { field: "toEmail", header: "Til" },
      { field: "fromName", header: "Fra navn" },
      { field: "fromEmail", header: "Fra" }
    ]);
  }

  private initializeEmails() {
    this.emails$ = this.customerService.getEmails(this.fromDate, this.toDate, null).pipe(
      tap((data: Array<EmailModelExt>) => {
        data.forEach(element => {
          element.dateSent = this.localizor.localizeDateTime(element.dateSentUtc);
          element.dateSentForSort = moment(element.dateSentUtc);
        });
      }),
      untilDestroyed(this),
      finalize(() => {
        this.loading = false;
      })
    );
  }

  public exportToExcel() {
    PrimeNgUtilities.exportCSV(this.table, this.cols, { bom: true });
  }

  customSort(event: SortEvent) {
    this.columns$.pipe(map(columns => columns.find(f => f.field === event.field))).subscribe(col => {
      let value1: any, value2: any;
      event.data.sort((data1, data2) => {
        if (col && col.sortField) {
          value1 = +data1[col.sortField];
          value2 = +data2[col.sortField];
        } else {
          value1 = data1[event.field];
          value2 = data2[event.field];
        }

        let result = null;
        if (value1 == null && value2 != null) {
          result = -1;
        } else if (value1 != null && value2 == null) {
          result = 1;
        } else if (value1 == null && value2 == null) {
          result = 0;
        } else if (typeof value1 === "string" && typeof value2 === "string") {
          result = value1.localeCompare(value2);
        } else {
          result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
        }
        return event.order * result;
      });
    });
  }
}
