import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@shared/shared.module";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { DialogModule } from "primeng/dialog";
import { EditorModule } from "primeng/editor";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { TableModule } from "primeng/table";
import { TabMenuModule } from "primeng/tabmenu";
import { ToastModule } from "primeng/toast";
import { ToolbarModule } from "primeng/toolbar";
import { SuperAdminCustomerCreateEditComponent } from "./superadmin-customer-createedit.component";
import { SuperAdminFarmListComponent } from "./superadmin-customer-farmlist.component";
import { SuperAdminCustomerIdComponent } from "./superadmin-customer-id.component";
import { SuperAdminCustomerUserListComponent } from "./superadmin-customer-userlist.component";
import { SuperAdminCustomerListComponent } from "./superadmin-customerlist.component";
import { SuperAdminEmailListComponent } from "./superadmin-emaillist.component";
import { FarmEditDialogComponent } from "./farm-edit-dialog/farm-edit-dialog.component";

const primeNgModules = [
  DialogModule,
  ConfirmDialogModule,
  ConfirmPopupModule,
  ToastModule,
  ToolbarModule,
  TableModule,
  InputTextareaModule,
  InputTextModule,
  ButtonModule,
  TabMenuModule,
  CalendarModule,
  EditorModule
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: "",
        component: SuperAdminCustomerListComponent
      },
      {
        path: "emails",
        component: SuperAdminEmailListComponent
      },
      {
        path: ":id",
        component: SuperAdminCustomerIdComponent,
        children: [
          // { path: "edit/:id", redirectTo: "main", pathMatch: "full" },
          { path: "main", component: SuperAdminCustomerCreateEditComponent },
          { path: "users", component: SuperAdminCustomerUserListComponent },
          { path: "farms", component: SuperAdminFarmListComponent }
        ]
      },
      {
        path: "create",
        component: SuperAdminCustomerCreateEditComponent
      }
    ]),
    ...primeNgModules,
    NgxExtendedPdfViewerModule
  ],
  declarations: [
    SuperAdminCustomerListComponent,
    SuperAdminCustomerIdComponent,
    SuperAdminCustomerCreateEditComponent,
    SuperAdminCustomerUserListComponent,
    SuperAdminEmailListComponent,
    SuperAdminFarmListComponent,
    FarmEditDialogComponent
  ]
})
export class SuperAdminCustomerModule {}
