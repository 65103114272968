import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivityDto } from "@apiModels/activityDto";
import { ActivityService } from "@globals/services/activity.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { userItems, userItemsWithAllAndNone } from "@shared/interfaces-and-enums/shared-data";
import { CellClickEventArgs, DayService, EventRenderedArgs, EventSettingsModel, MonthService, SelectEventArgs, WeekService, WorkWeekService } from "@syncfusion/ej2-angular-schedule";
import { L10n, loadCldr, setCulture } from "@syncfusion/ej2-base";
import { SelectItem } from "primeng/api";
import { activityTypeItems, categoryItems, UserInfo, userInfos } from "./shared-data";

// Import the required CLDR JSON files for Danish culture
import * as caGregorian from "cldr-data/main/da/ca-gregorian.json";
import * as currencies from "cldr-data/main/da/currencies.json";
import * as numbers from "cldr-data/main/da/numbers.json";
import * as timeZoneNames from "cldr-data/main/da/timeZoneNames.json";
import * as numberingSystems from "cldr-data/supplemental/numberingSystems.json";
import * as weekData from "cldr-data/supplemental/weekData.json";

import { BiLocalizationHelperService } from "@core/utility-services/bi-localization-helper.service";
import { ActivityDtoExt } from "@globals/ExtModels/ActivityDtoExt";
import moment from "moment";
import { Observable } from "rxjs";

// Import the required CLDR JSON files for Danish culture

@UntilDestroy()
@Component({
  templateUrl: "./activity-calender.component.html",
  styleUrls: ["./activity-calender.component.scss"],
  providers: [DayService, WeekService, MonthService, WorkWeekService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityCalenderComponent implements OnInit {
  public userId = +window.sessionStorage.getItem("userId");
  public loading = true;
  public itemList: Array<ActivityDtoExt> = [];
  public itemList$: Observable<Array<ActivityDtoExt>>;
  public appointments: Array<any> = [];
  public activities: Array<ActivityDto> = [];
  public selectedItem: ActivityDto | null = null;
  public dialogVisible = false;

  public userItemsWithAllAndNone = userItemsWithAllAndNone;
  public selectedUserItem: SelectItem;
  public selectedStatusFilterItem: SelectItem;
  public statusFilterItems: Array<SelectItem> = [
    { label: "Alle", value: "" },
    { label: "Aktuelle", value: "Aktuelle" },
    { label: "Overskredne", value: "Overskredne" },
    { label: "Opgave", value: "TASK" },
    { label: "Møde", value: "MEETING" },
    { label: "Email", value: "EMAIL" }
  ];
  public categoryItems = categoryItems;
  public activityTypeItems = activityTypeItems;
  public selectedCategoryItems: SelectItem[] = [];
  public userItems = userItems;

  public currentDate = new Date();

  public selectedDate: Date = new Date();
  public showWeekend: boolean = false;
  public eventSettings: EventSettingsModel;
  //public userColorsKeys: string[];

  // private userColors = {
  //   "Magnus Timmermann": "blue",
  //   "Glennie Christensen": "green",
  //   "Rebecca Sachse": "red",
  //   "Nina Jebens": "purple",
  //   "Niels Schultz": "brown",
  //   "Claus Elmann": "white"
  // };

  public userInfos: { [key: string]: UserInfo } = userInfos;
  public userColorsKeys: string[] = Object.keys(this.userInfos);

  private multiUserColor = "#003366";

  constructor(
    public activityService: ActivityService,
    private cd: ChangeDetectorRef,
    private localizor: BiLocalizationHelperService
  ) {
    //this.userColorsKeys = Object.keys(this.userColors);
  }

  ngOnInit() {
    loadCldr(numberingSystems, timeZoneNames, weekData, numbers, caGregorian, currencies);

    setCulture("da");

    L10n.load({
      da: {
        schedule: {
          day: "Dag",
          week: "Uge",
          workWeek: "Arbejdsuge",
          month: "Måned",
          agenda: "Dagsorden",
          today: "I dag",
          noEvents: "Ingen begivenheder",
          allDay: "Hele dagen",
          start: "Start",
          end: "Slut",
          more: "mere",
          close: "Luk"
        }
      }
    });

    this.resetFilter(false);

    this.initializeItems();
  }

  public handleFilterChange() {
    this.initializeItems();
  }

  public resetFilter(refresh: boolean) {
    this.selectedUserItem = this.userItemsWithAllAndNone.find(item => item.value === -1);
    this.selectedStatusFilterItem = this.statusFilterItems.find(item => item.value === "");
    this.selectedCategoryItems = this.activityTypeItems;

    if (refresh) {
      this.initializeItems();
    }
  }

  public initializeItems() {
    const filterDto = this.getActivityFilterDto();
    this.activityService
      .getActivities(filterDto)
      .pipe(untilDestroyed(this))
      .subscribe((data: Array<ActivityDto>) => {
        this.activities = data;
        this.appointments = data.map(element => ({
          Id: element.id,
          Subject: this.userShort(this.buildAssignedUsersString(element)) + element.title || "No Title",
          StartTime: moment(element.datePlannedStartUtc).toDate() || new Date(),
          EndTime: moment(element.datePlannedEndUtc).toDate() || new Date(),
          Description: element.description,
          Location: element.place,
          Category: this.categoryItems.find(c => c.value === element.categoryId)?.label,
          User: this.buildAssignedUsersString(element),
          RecurrenceRule: element.recurrenceRule, // Include the recurrence rule here
          IsAllDay: element.isAllDay // Map the IsAllDay property
        }));

        this.eventSettings = {
          dataSource: this.appointments,
          enableTooltip: true,
          tooltipTemplate: this.tooltipTemplate
        };
        this.loading = false;
        this.cd.markForCheck();
      });
  }

  public tooltipTemplate: string = `
  <div class="tooltip-wrap">
    <div class="content-area">
      <div class="name">Title: \${Subject}</div>
      <div class="user">Assigned To: \${User}</div>
      <div class="description">Description: \${Description}</div>
      <div class="category">Category: \${Category}</div>
      <div class="isallday">Heldags: \${IsAllDay}</div>
      <div class="recurrence">Recurrence: \${RecurrenceRule}</div>
      <div class="time">From: \${StartTime.toLocaleString()}</div>
      <div class="time">To: \${EndTime.toLocaleString()}</div>
    </div>
  </div>
`;

  buildAssignedUsersString(activity: ActivityDto): string {
    if (!activity.assignments || activity.assignments.length === 0) {
      return "";
    }

    const assignedUsers = activity.assignments.map(assignment => assignment.assignedToUser);
    return assignedUsers.join(", ");
  }

  // private userShort(assignedUsersString) {
  //   switch (assignedUsersString) {
  //     case "Nina Jebens":
  //       return "NJ-";

  //     case "Magnus Timmermann":
  //       return "MT-";

  //     case "Niels Schultz":
  //       return "NS-";

  //     case "Glennie Christensen":
  //       return "GC-";

  //     case "Claus Elmann":
  //       return "CE-";
  //     default:
  //       return "";
  //   }
  // }

  onEventClick(event: any) {
    this.selectedItem = this.activities.find(appt => appt.id === event.event.Id) || null;
    this.dialogVisible = true;
    this.cd.detectChanges();
  }

  public onCellSelect(args: SelectEventArgs): void {
    // Tjek om data er tilgængelig
    if (args.data && args.data.length) {
      // Iterér over alle valgte celler
      args.data.forEach(cell => {
        const startTime = new Date(cell.startTime); // Starttidspunkt
        const endTime = new Date(cell.endTime); // Sluttidspunkt
        const isAllDay = cell.isAllDay; // Heldagsbegivenhed

        console.log("Start Time:", startTime);
        console.log("End Time:", endTime);
        console.log("Is All Day:", isAllDay);
      });
    } else {
      console.log("Ingen celler er valgt.");
    }
  }

  public addNewActivity(args: CellClickEventArgs) {
    const newActivity: ActivityDto = {
      id: 0,
      title: "",
      place: "",
      activityTypeId: activityTypeItems[0].value,
      categoryId: categoryItems[0].value,
      createdByUserId: this.userId,
      plannedDurationMinutes: (args.endTime.getTime() - args.startTime.getTime()) / (1000 * 60),
      isAllDay: args.isAllDay,
      datePlannedStartUtc: args.startTime.toISOString(),
      datePlannedEndUtc: args.endTime.toISOString(),
      dateCreatedUtc: new Date().toISOString(),
      dateModifiedUtc: null,
      dateReminderUtc: null,
      dateCompletedUtc: null,
      completedByUserId: null,
      isReminderEnabled: true,
      deleted: false,
      createInCalender: false,
      createCalenderInUserId: null,
      description: "",
      calendarEventId: null,
      createdInCalendarEmail: null,
      calendarDirty: false,
      prospectId: null,
      customerId: null,
      hubspotCompanyId: null,

      assignments: [
        {
          assignedToUserId: this.userId,
          assignedToUser: this.userItems.find(item => item.value === this.userId)?.label || "Unknown User"
        }
      ]
    };
    this.selectedItem = newActivity;
    this.dialogVisible = true;
    this.cd.detectChanges();
  }

  onEventRendered(args: EventRenderedArgs): void {
    const userInitials = this.getUserInitials(args.data["User"]);
    const userInfo = Object.values(this.userInfos).find(u => u.initials === userInitials);

    if (userInfo) {
      if (args.element) {
        args.element.style.backgroundColor = userInfo.colorHex;
      }
    } else if (args.data["User"].includes(",")) {
      if (args.element) {
        args.element.style.backgroundColor = this.multiUserColor;
      }
    } else {
      if (args.element) {
        args.element.style.backgroundColor = "#000000"; // Default color
      }
    }
  }

  private getUserInitials(fullName: string): string {
    const user = Object.values(this.userInfos).find(u => u.fullName === fullName);
    return user ? user.initials : "";
  }

  private userShort(assignedUsersString: string): string {
    const user = Object.values(this.userInfos).find(u => assignedUsersString.includes(u.fullName));
    return user ? user.initials + "-" : "";
  }

  public getActivityFilterDto() {
    return {
      userId: this.selectedUserItem?.value ?? null,
      kategories: this.selectedCategoryItems.map(item => item.value),
      status: this.selectedStatusFilterItem?.value ?? null
    };
  }

  handleCloseDialog() {
    this.dialogVisible = false;
    this.selectedItem = null;
    this.initializeItems();
  }
}
