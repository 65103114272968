<div class="layout-topbar">
  <a class="layout-topbar-logo" routerLink="">
    <img src="assets/layout/images/logo.png" alt="logo" />

    <!-- <span>NeeoBovis</span> -->
  </a>
  <a class="p-link layout-menu-button layout-topbar-button" href="#" (click)="appMain.toggleMenu($event)">
    <i class="pi pi-bars"></i>
  </a>

  <a class="p-link layout-topbar-menu-button layout-topbar-button" href="#" (click)="appMain.toggleTopMenu($event)">
    <i class="pi pi-ellipsis-v"></i>
  </a>

  <div class="layout-topbar-menu" [ngClass]="{ 'layout-topbar-menu-mobile-active': appMain.topMenuActive }">
    {{ (currentUser$ | async)?.name }}

    <!-- <a href="#" class="p-link layout-topbar-button">
      <i class="pi pi-calendar"></i>
      <span>Calendar</span>
    </a>
    <a href="#" (click)="onTabDemoClicked()" class="p-link layout-topbar-button">
      <i class="pi pi-cog"></i>
      <span>Settings</span>
    </a>
    <a *ngIf="isLoggedIn" href="#" (click)="onProfileClicked()" class="p-link layout-topbar-button">
      <i class="pi pi-user"></i>
      <span>Profile</span>
    </a> -->

    <a *ngIf="isLoggedIn" href="#" (click)="onLogoutClicked()" class="p-link layout-topbar-button">
      <i class="pi pi-sign-out"></i>
      <span>Log ud</span>
    </a>
  </div>
</div>
