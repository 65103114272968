<div id="home" class="surface-0 overflow-hidden">
  <div class="py-4 px-4 mx-0 md:mx-6 lg:mx-8 lg:px-8 flex align-items-center justify-content-between relative lg:static">
    <a class="flex align-items-center" href="#">
      <img src="https://neeobovis.dk/wp-content/uploads/2023/04/Logo_boovis_groen.png" alt="Neeo Bovis Logo" height="50" class="mr-0 lg:mr-2" /><span
        class="text-900 font-medium text-2xl line-height-3 mr-8"
      ></span>
    </a>
    <a pRipple class="cursor-pointer block lg:hidden text-700" pStyleClass="@next" enterFromClass="hidden" leaveToClass="hidden" [hideOnOutsideClick]="true">
      <i class="pi pi-bars text-4xl"></i>
    </a>
    <div class="align-items-center surface-0 flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 px-6 lg:px-0 z-2" style="top: 92%">
      <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row cursor-pointer">
        <li>
          <a (click)="router.navigate(['/pages/landing'], { fragment: 'home' })" pRipple class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3">
            <span>Menu 1</span>
          </a>
        </li>
        <li>
          <a (click)="router.navigate(['/pages/landing'], { fragment: 'features' })" pRipple class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3">
            <span>Menu 2</span>
          </a>
        </li>
        <li>
          <a (click)="router.navigate(['/pages/landing'], { fragment: 'highlights' })" pRipple class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3">
            <span>Menu 3</span>
          </a>
        </li>
        <li>
          <a (click)="router.navigate(['/pages/landing'], { fragment: 'pricing' })" pRipple class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3">
            <span>Menu 4</span>
          </a>
        </li>
      </ul>
      <div class="flex justify-content-between lg:block border-top-1 lg:border-top-none surface-border py-3 lg:py-0 mt-3 lg:mt-0">
        <a routerLink="/login"> <button pButton pRipple label="Login" class="p-button-rounded border-none ml-5 font-light line-height-2 bg-blue-500 text-white"></button></a>

      </div>
    </div>
  </div>

  <div class="fullscreen-container">
    <img src="https://neeobovis.dk/wp-content/uploads/2023/05/Bovis.jpg" alt="Bovis image" class="fullscreen-image" />
  </div>
</div>
