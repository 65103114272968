<div class="flex align-items-left">
  <p-checkbox [binary]="true" class="cb-large ml-3" inputId="mapped" label="Vis slettede" [ngModel]="showDeleted" (ngModelChange)="onShowActiveChange($event)"></p-checkbox>
</div>

<div class="grid">
  <div class="col-12">
    <div class="card">
      <p-toast></p-toast>
      <p-table
        #table
        [value]="users$ | async"
        [columns]="cols"
        [globalFilterFields]="cols"
        [loading]="loading"
        selectionMode="single"
        [(selection)]="selectedUser"
        dataKey="id"
        (onRowSelect)="onRowSelect($event)"
        csvSeparator=";"
        [exportFilename]="'export'"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        responsiveLayout="scroll"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[10, 25, 50]"
      >
        <ng-template pTemplate="caption">
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 class="m-0">Brugere</h5>

            <button pButton pRipple label="Opret ny bruger" icon="pi pi-plus" class="p-button-success mr-2" (click)="onAddNewUserClick()"></button>

            <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help" (click)="exportToExcel()"></button>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="table.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ col.header }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
            <td *ngFor="let col of columns">
              {{ rowData[col.field] }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
