<div class="card">
  <div class="card-header">
    {{ pageTitle }}
  </div>

  <div class="card-body" *ngIf="user">
    <p-tabMenu [model]="items" [activeItem]="activeItem"></p-tabMenu>
  </div>

  <router-outlet></router-outlet>

  <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
</div>
