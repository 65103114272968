import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, HostListener, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DefaultUrlSerializer, Router, RouterStateSnapshot } from "@angular/router";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { AuthenticationResult, EventMessage, EventType } from "@azure/msal-browser";
import { GlobalStateAndEventsService } from "@core/global-state-and-events.service";
import { CustomValidators } from "@globals/classes/custom-validators";
import { isRunningInIE } from "@globals/helper-functions";
import { UserService } from "@globals/services/user.service";
import { BiHttpErrorResponse } from "@models/common/BiHttpErrorResponse";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TranslateService } from "@ngx-translate/core";
import { BiCustomValidators } from "@shared/classes/BiCustomValidators";
import { RouteNames } from "@shared/classes/RouteNames";
import { WindowSessionStorageNames } from "@shared/variables-and-functions/WindowSessionStorageNames";
import { Observable, filter, finalize, of, take } from "rxjs";
import { BiLocalAndSessionStorageService } from "src/app/service/bi-local-and-session-storage.service";

@UntilDestroy()
@Component({
  selector: "app-login",
  templateUrl: "./el-login.component.html",
  styleUrls: ["./el-login.component.scss"]
})
export class ElLoginComponent implements OnInit, AfterViewInit {
  constructor(
    private userService: UserService,
    private router: Router, // Direkte injektion af Router
    private eventsManager: GlobalStateAndEventsService,
    private translator: TranslateService,
    private cd: ChangeDetectorRef,
    private adAuthService: MsalService,
    private broadcastService: MsalBroadcastService,
    private localAndSessionStorageService: BiLocalAndSessionStorageService
  ) {}

  public loginForm: UntypedFormGroup;
  public hideLoginForm = false;
  public warnIEUser: boolean;
  public failedLoginMessage: string;
  public _isLoading: boolean;
  public showResetPassContent = false;
  public emailIsReadonly = false;

  @HostBinding("class.is-loading") get isLoading() {
    return this._isLoading;
  }

  @HostListener("document:keydown.escape", ["$event"]) escapePressed(event) {
    this.onCloseClicked();
  }

  @ViewChild("loginEmail") private loginEmail: ElementRef<HTMLInputElement>;

  /**
   * Control for the email-input in the Reset Password functionality. Notice: this wont be part of the "loginForm" an so  we must use [formControl] expression in tempalte
   * to make it stand alone and register with the form
   */
  public resetPassEmailControl = new UntypedFormControl("", [Validators.required, BiCustomValidators.email()]);


  ngOnInit(): void {
    const attemptedRoute = this.eventsManager.getCurrentStateValue()?.routeAfterLogin;

    if (attemptedRoute) {
      const parsedRoute = new DefaultUrlSerializer().parse(attemptedRoute);
    }

    this.loginForm = new UntypedFormGroup({
      loginEmail: new UntypedFormControl("", [Validators.required, BiCustomValidators.email()]),
      password: new UntypedFormControl("", [Validators.required])
    });

    // If running in the shit hole browser, warn user
    if (!window.sessionStorage.getItem(WindowSessionStorageNames.IEWarningSeen) && isRunningInIE()) this.warnIEUser = true;

    this.broadcastService.msalSubject$
      .pipe(
        untilDestroyed(this),
        filter(e => {
          return e.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || e.eventType === EventType.LOGIN_SUCCESS;
        })
      )
      .subscribe(eventMsg => {
       //alert("broadcastService.msalSubject$ 1" + eventMsg);
        if (this.eventsManager.getCurrentStateValue()?.routeAfterLogin && this.eventsManager.getCurrentStateValue().routeAfterLogin.indexOf("/login#code=") > -1) {
          this.eventsManager.setState({
            ...this.eventsManager.getCurrentStateValue(),
            routeAfterLogin: undefined
          });
        }

        this.email.setValue((eventMsg.payload as AuthenticationResult).account.username);
        this.doAdLogin((eventMsg.payload as AuthenticationResult).idToken);
      });

    // Set up for Azure AD login
    this.broadcastService.msalSubject$
      .pipe(
        untilDestroyed(this),
        filter(e => e.eventType === EventType.ACQUIRE_TOKEN_FAILURE)
      )
      .subscribe((_result: EventMessage) => {
        //alert("broadcastService.msalSubject$ 2"+ _result);
        this._isLoading = false;
        this.cd.markForCheck();
      });
  }

  ngAfterViewInit() {
    if (this.loginEmail) this.loginEmail.nativeElement.focus();
  }

  public login() {
    if (this.email.valid && this.password.valid) {
      this.failedLoginMessage = undefined;
      this.email.setErrors(undefined);
      this.password.setErrors(undefined);
      this.loginForm.markAsUntouched();

      this._isLoading = true;

      this.userService
        .login(this.email.value, this.password.value)
        .pipe(
          take(1),
          finalize(() => {
            this._isLoading = false;
            this.cd.detectChanges();
          })
        )
        .subscribe(
          newUser => {
            // Successfull login
            this.loginForm.reset();
            this.eventsManager.loginEvent.next(newUser);
            this.hideLoginForm = true;
            this.router.navigate([""]);
          },
          (err: BiHttpErrorResponse) => {
            // Slide in Choose delivery Method
            if (err.status === 300) {
              alert("login 1");
              //this.slideContainerValue = 2;
            }
            // Send Email and go to Input
            else if (err.status === 428) {
              this.sendPinCodeByEmail();
            } else {
              this.handleFailedLogin(err);
            }
          }
        );
    }
  }

  public sendPinCodeByEmail() {
    this.userService.sendCodeByEmail(this.email.value).subscribe(response => {
      // this.slideContainerValue = 3;
      this.cd.detectChanges();
    });
  }

  public onCloseClicked() {
    this.router.navigate([RouteNames.frontPage]);
  }

  public onDismissIEWarning() {
    // Use localstorage to store the info that user has seen and dismissed the IE warning
    window.sessionStorage.setItem(WindowSessionStorageNames.IEWarningSeen, "1");
    this.warnIEUser = false;
  }

  //==== Getters for easy access to form control's data from the view
  public get email() {
    return this.loginForm.get("loginEmail");
  }
  public get password() {
    return this.loginForm.get("password");
  }
  //==================

  private handleFailedLogin(err: BiHttpErrorResponse, isAdLogin = false) {
    this._isLoading = false;
    if (err.status === 403) {
      if (!isAdLogin) {
        alert("handleFailedLogin 1");
        //this.generateLoginLockedDialogContent().subscribe(content => this.biDialogService.open(content));
      } else {
        alert("handleFailedLogin 2");
        //this.pDialogService.open(AdLoginInfoBoxComponent, { header: this.translator.instant("shared.LoginAD"), baseZIndex: 15000 });
      }
    } else {
      this.failedLoginMessage = err.error.errorMessage;
    }
  }

  /**
   * Implementation of custom CanDeactivate interface CanDeactivateComponent.
   */
  canIDeactivate(nextState: RouterStateSnapshot): Observable<boolean> {
    // Only if navigating to frontpage, it's okay to leave.
    return of(nextState.url.indexOf(RouteNames.frontPage) !== -1);
  }

  public loginAD() {
    this.failedLoginMessage = undefined;
    this.email.setErrors(undefined);
    this.password.setErrors(undefined);
    this._isLoading = true;

    this.adAuthService.loginRedirect();
  }

  private doAdLogin(idToken: string) {
    this.userService
      .loginAD(idToken)
      .pipe(
        finalize(() => {
          this._isLoading = false;
          this.cd.markForCheck();
        })
      )
      .subscribe({
        next: newUser => {
          //this.localAndSessionStorageService.setItem(WindowSessionStorageNames.adLogin, "1");
          // Successful login
          this.loginForm.reset();
          this.eventsManager.loginEvent.next(newUser);
          this.hideLoginForm = true;
          this.router.navigate([""]);
        },
        error: (err: BiHttpErrorResponse) => {
          // Slide in Choose delivery Method
          if (err.status === 300) {
            // TODO
            alert("doAdLogin error 300");
            //this.slideContainerValue = 2;
          }
          // Send Email and go to Input
          else if (err.status === 428) {
            //this.localAndSessionStorageService.setItem(WindowSessionStorageNames.adLogin, "1");
            if ((<any>err.error)?.authenticatorApp ?? false) {
              // TODO
              alert("doAdLogin error 428");
              //this.slideContainerValue = 5;
            } else {
              this.sendPinCodeByEmail();
            }
          } else {
            this.localAndSessionStorageService.removeItem(WindowSessionStorageNames.adLogin);
            this.handleFailedLogin(err, true);
          }
        }
      });
  }
}
