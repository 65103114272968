import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { of } from "rxjs";
import { switchMap, take } from "rxjs/operators";

import { CustomerModel } from "@apiModels/customerModel";

import { BiCountryId, BiLanguageId } from "@enums/BiLanguageAndCountryId";
import { CustomerService } from "@globals/services/customer.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BiCustomAnimations } from "@shared/classes/BICustomAnimations";
import { MessageService, SelectItem } from "primeng/api";

interface MainFormValue {
  name: string;
  address: string;
  phone: string;
  email: string;
  deleted: boolean;
  publicId: string;
  languageId: number;
  countryId: number;
  companyRegistrationId: string;
  timeZoneId: string;
  info: string;
}

@UntilDestroy()
@Component({
  templateUrl: "./superadmin-customer-createedit.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [BiCustomAnimations.fadeInDown, BiCustomAnimations.fadeIn]
})
export class SuperAdminCustomerCreateEditComponent implements OnInit {
  public customer?: CustomerModel;
  public mainForm: UntypedFormGroup;
  public showFormErrorMessage: boolean;
  public loading = false;
  public errorMessage = "";

  public createOrSave: string;

  @HostBinding("@fadeInDown") get fadeInHost() {
    return true;
  }

  constructor(private activeRoute: ActivatedRoute, private router: Router, private customerService: CustomerService, private cd: ChangeDetectorRef, private messageService: MessageService) {}

  ngOnInit() {
    this.activeRoute.parent.params
      .pipe(
        untilDestroyed(this),
        take(1),
        switchMap(params => {
          if (!params["id"] || isNaN(+params["id"])) return of(this.customerService.initializeCustomerModel());
          if (+params["id"] === 0) return of(this.customerService.initializeCustomerModel());
          this.customerService.currentCustomerId = +params["id"];
          return this.customerService.getCustomer(+params["id"], null);
        })
      )
      .subscribe(data => {
        if (data) {
          this.customer = data;
          if (this.customer.id === 0) this.createOrSave = "Opret";
          else this.createOrSave = "Gem";
        }
        this.initFormGroup();
        this.cd.detectChanges();
      });
  }

  private initFormGroup() {
    this.mainForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.customer?.name ?? "", [Validators.required, Validators.minLength(3)]),
      address: new UntypedFormControl(this.customer?.address ?? "", [Validators.required, Validators.minLength(3)]),
      phone: new UntypedFormControl(this.customer?.phone ?? "", [Validators.maxLength(50)]),
      email: new UntypedFormControl(this.customer?.email ?? "", [Validators.email]),
      languageId: new UntypedFormControl(this.customer?.languageId ?? 1, [Validators.required]),
      countryId: new UntypedFormControl(this.customer?.countryId ?? 1, [Validators.required]),
      companyRegistrationId: new UntypedFormControl(this.customer?.companyRegistrationId ?? ""),
      info: new UntypedFormControl(this.customer?.info ?? ""),
      deleted: new UntypedFormControl(this.customer?.deleted ?? false)
    });
  }

  public get name() {
    return this.mainForm.get("name");
  }

  public get address() {
    return this.mainForm.get("address");
  }

  public get phone() {
    return this.mainForm.get("phone");
  }

  public get email() {
    return this.mainForm.get("email");
  }

  public get languageId() {
    return this.mainForm.get("languageId");
  }

  public get countryId() {
    return this.mainForm.get("countryId");
  }

  public get companyRegistrationId() {
    return this.mainForm.get("companyRegistrationId");
  }

  public get info() {
    return this.mainForm.get("info");
  }

  public get deleted() {
    return this.mainForm.get("deleted");
  }

  private createObjectFromFormValue(formValue: MainFormValue): CustomerModel {
    const returnObject: Partial<CustomerModel> = {
      ...formValue,
      id: 0
    };

    if (this.customer) {
      returnObject.id = this.customer.id;
    }

    return returnObject;
  }

  private checkAndValidateForm() {
    if (this.mainForm.invalid) {
      Object.keys(this.mainForm.controls).forEach(cName => this.mainForm.controls[cName].markAsTouched());
      this.showFormErrorMessage = true;
      return false;
    }

    this.showFormErrorMessage = false;
    return true;
  }

  public onSaveClicked() {
    this.saveCustomer();
  }

  public saveCustomer() {
    if (!this.checkAndValidateForm()) return;

    const stay = this.customer.id === 0;

    const formValue = this.mainForm.value as MainFormValue;

    const newOrUpdatedObject = this.createObjectFromFormValue(formValue) as CustomerModel;

    this.customerService.updateCustomer(newOrUpdatedObject).subscribe({
      next: customerId => {
        if (this.customer.id === 0) this.customer.id = customerId;

        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Data blev gemt"
        });

        return this.onSaveComplete(stay);
      },
      error: err => (this.errorMessage = err)
    });
  }

  onSaveComplete(stay: boolean): void {
    // Reset the form to clear the flags
    this.mainForm.reset();
    if (stay) this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => this.router.navigate(["/superadmin/customers", this.customer.id, "main"]));
    else this.router.navigate(["/superadmin/customers"]);
  }

  public languages: Array<SelectItem> = [
    // { value: null, label: "Vælg sprog" },
    { value: BiLanguageId.DK, label: "Dansk" },
    { value: BiLanguageId.SE, label: "Svensk" },
    { value: BiLanguageId.FI, label: "Finsk" },
    { value: BiLanguageId.NO, label: "Norsk" }
  ];

  public countries: Array<SelectItem> = [
    // { value: null, label: "Vælg land" },
    { value: BiCountryId.DK, label: "Danmark" },
    { value: BiCountryId.SE, label: "Sverige" },
    { value: BiCountryId.FI, label: "Finland" },
    { value: BiCountryId.NO, label: "Norge" }
  ];

  getFormValidationErrors() {
    console.log("%c ==>> Validation Errors: ", "color: red; font-weight: bold; font-size:25px;");
    let totalErrors = 0;
    Object.keys(this.mainForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.mainForm.get(key).errors;
      if (controlErrors != null) {
        totalErrors++;
        Object.keys(controlErrors).forEach(keyError => {
          console.log("Key control: " + key + ", keyError: " + keyError + ", err value: ", controlErrors[keyError]);
        });
      }
    });
    console.log("Number of errors: ", totalErrors);
  }
}
