<ejs-kanban keyField="Status" [dataSource]="data" [cardSettings]="cardSettings" [swimlaneSettings]="swimlaneSettings" (dragStop)="onDragStop($event)">
  <e-columns>
    <e-column headerText="To Do" keyField="ToDo"></e-column>
    <e-column headerText="In Progress" keyField="InProgress"></e-column>
    <e-column headerText="Ready for Test" keyField="ReadyForTest"></e-column>
    <e-column headerText="Done" keyField="Done"></e-column>
  </e-columns>

  <!-- Custom card template with assignee dropdown -->
  <ng-template #cardSettingsTemplate let-data>
    <div class="e-card-content">
      <div class="card-title">{{ data.Summary }}</div>
      <div class="card-assignee">
        <span class="assignee-label">Assigned to: </span>
        <p-dropdown
          [options]="userInfosArray"
          [(ngModel)]="data.AssigneeUserId"
          optionLabel="fullName"
          optionValue="id"
          (onChange)="onAssigneeChange($event, data)"
          placeholder="Select Assignee"
          [ngModelOptions]="{ standalone: true }"
        >
        </p-dropdown>
        <span class="card-type">{{ data.Type }}</span>
      </div>
    </div>
  </ng-template>
</ejs-kanban>
