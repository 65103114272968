import { Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { GlobalStateAndEventsService } from "@core/global-state-and-events.service";
import { UserService } from "@globals/services/user.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { RouteNames } from "@shared/classes/RouteNames";
import { debug } from "console";
import { PrimeNGConfig } from "primeng/api";
import { filter } from "rxjs";

@UntilDestroy()
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html"
})
export class AppComponent {
  menuMode = "static";

  constructor(
    private primengConfig: PrimeNGConfig,
    public userService: UserService,
    private eventsAndStateManager: GlobalStateAndEventsService,
    public router: Router
  ) {}

  ngOnInit() {
    this.primengConfig.ripple = true;
    document.documentElement.style.fontSize = "14px";
    //this.setupSubscriptions();
  }

  private setupSubscriptions() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe((event: NavigationEnd) => {
        // Make sure to always navigate to the "landing" page if url is "/"
        if (event.urlAfterRedirects) {
          if (event.urlAfterRedirects === "/") {
            //this.router.navigate(["/pages/landing"])
          }
          else if (
            event.urlAfterRedirects !== "/login" &&
            event.urlAfterRedirects !== "/frontpage" &&
            event.urlAfterRedirects !== "/transparent-login" &&
            !event.urlAfterRedirects.startsWith("/reset-password")
          ) {
            this.eventsAndStateManager.setState({
              ...this.eventsAndStateManager.getCurrentStateValue(),
              routeAfterLogin: event.urlAfterRedirects
            });
          }
        }
      });

    // Subscribe to login/logout events
    this.eventsAndStateManager.loginEvent.pipe(untilDestroyed(this)).subscribe(() => this.onLoginSuccess());
    this.eventsAndStateManager.resetPassEmailSent.pipe(untilDestroyed(this)).subscribe(() => this.onResetPasswordEmailSent());
  }

  private onLoginSuccess() {
    // Check if user was using a deep link before loggin in
    let attemptedRoute = this.eventsAndStateManager.getCurrentStateValue().routeAfterLogin;

    if (!attemptedRoute) {
      const url = `${window.location.pathname}${window.location.search}`;

      if (url !== "/" && url !== "/login" && url !== "/frontpage" && url !== "/transparent-login" && !url.startsWith("/reset-password")) {
        attemptedRoute = url;
      }
    }

    this.router.navigate([attemptedRoute]);
  }

  private onResetPasswordEmailSent() {
    this.userService.clearUser();
  }
}
