<p-dialog header="Rediger note" [(visible)]="visible" [modal]="true" [style]="{ 'min-width': '40vw', width: 'auto' }" (onHide)="closeDialog()">
  <div class="p-grid p-fluid">
    <div class="p-col-12">
      <div class="p-field">
        <label for="noteType">Type</label>
        <p-dropdown id="noteType" optionLabel="label" dataKey="value" [options]="noteTypes" [(ngModel)]="selectedNoteType" placeholder="Vælg type"></p-dropdown>
      </div>
    </div>

    <div class="p-col-12">
      <div class="p-field">
        <label for="noteText">Note</label>
        <textarea id="noteText" rows="5" [(ngModel)]="noteText" pInputTextarea autoResize="autoResize"></textarea>
      </div>
    </div>

    <div class="p-col-12">
      <div class="p-field followup-container">
        <label for="followupDate">Opfølgning</label>
        <div class="date-time-wrapper">
          <p-calendar id="followupDate" [(ngModel)]="selectedDate" placeholder="Vælg en dato" dateFormat="dd-mm-yy" [yearNavigator]="true" yearRange="2020:2040" appendTo="body"></p-calendar>
          <p-dropdown id="followupTime" [options]="timeIntervals" [(ngModel)]="selectedTime" placeholder="Vælg tidspunkt" appendTo="body"></p-dropdown>
        </div>
      </div>
    </div>
  </div>

  <p-footer>
    <button pButton label="Gem" icon="pi pi-check" class="p-button-success" (click)="saveNote()"></button>
    <button pButton label="Annuller" icon="pi pi-times" class="p-button-secondary" (click)="closeDialog()"></button>
  </p-footer>
</p-dialog>
