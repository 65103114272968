import { HttpClient } from "@angular/common/http";
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HubspotCompanyDto } from "@apiModels/hubspotCompanyDto";
import { HubspotCompanyMapDto } from "@apiModels/hubspotCompanyMapDto";
import { HubspotService } from "@globals/services/hubspot.service";
import { statusFilterItems, userItemsWithNotSelected } from "@shared/interfaces-and-enums/shared-data";
import { WindowSessionStorageNames } from "@shared/variables-and-functions/WindowSessionStorageNames";
import * as L from "leaflet";
import { SelectItem } from "primeng/api";
import { Observable, switchMap, take } from "rxjs";

@Component({
  selector: "app-neeo-bovis-kort-ansvar",
  templateUrl: "./neeo-bovis-kort-ansvar.component.html",
  styleUrls: ["./neeo-bovis-kort-ansvar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KortByAnsvarComponent implements OnInit, OnDestroy, AfterViewInit {
  public fraKoer = 94;
  public tilKoer = 5000;
  //public skjulKunder = true;
  map: L.Map;

  farms: any[] = [];
  filteredFarms: any[] = [];
  public data$: Observable<HubspotCompanyMapDto>;

  public hubspotCompanyId?: number;

  public selectedUserItems: SelectItem[] = [];

  public selectedStatusItems: SelectItem[] = [];

  public selectedMejeriItems: SelectItem[] = [];

  public farmUserItem: SelectItem;

  public farmStatusItem: SelectItem;

  public selectedFarm: HubspotCompanyMapDto;

  public farmDialogVisible = false;

  public userId = +window.sessionStorage.getItem(WindowSessionStorageNames.userId);

  selectedMarkers: HubspotCompanyMapDto[] = [];

  markers: L.Marker[] = [];

  public userFilterItems = userItemsWithNotSelected;

  public userItems = userItemsWithNotSelected;

  public statusFilterItems = statusFilterItems;

  public mejeriItems: Array<SelectItem> = [
    { value: null, label: "Ingen" },
    { value: "Naturmælk", label: "Naturmælk" },
    { value: "Thise", label: "Thise" }
  ];

  constructor(
    public hubspotService: HubspotService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.hasOwnProperty("id")) {
        this.hubspotCompanyId = +params["id"];
      }

      this.selectedUserItems = this.userFilterItems;
      this.selectedStatusItems = this.statusFilterItems.filter(item => item.label !== "Kunde" && item.label !== "Afvist");
      this.selectedMejeriItems = this.mejeriItems;
      this.loadFarms();
    });
  }

  ngAfterViewInit() {
    this.initMap();
  }

  ngOnDestroy() {
    if (this.map) {
      this.map.remove();
    }
  }

  private initMap() {
    this.map = L.map("map").setView([56.1689, 9.5549], 5); // Silkeborg, Denmark

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: "© OpenStreetMap contributors"
    }).addTo(this.map);

    this.map.setMinZoom(7); // kun dk
  }

  private createMarkerIcon(markerColor: string, border: string, textColor: string, width: number, height: number, koer: number, selected: boolean): L.DivIcon {
    const selectedRingStyle = selected ? "box-shadow: 0 0 0 4px orange;" : "";

    return L.divIcon({
      className: "custom-div-icon",
      html: `
      <div style="
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${markerColor};
        ${border}
        color: ${textColor};
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        width: ${width}px;
        height: ${height}px;
        line-height: 1;
        border-radius: 50%;
        ${selectedRingStyle} /* Apply the selected ring style */
      " class="marker-koe">${koer}</div>
    `,
      iconSize: [30, 30]
    });
  }

  private createMarker(farm: HubspotCompanyMapDto, selected: boolean): L.Marker {
    let markerColor;
    let textColor;
    let border = "";
    let width = 25;
    let height = 20;

    switch (farm.koer?.toString().length) {
      case 1:
      case 2:
        width = 30;
      case 3:
        width = 35;
        break;
      case 4:
        width = 45;
        break;
    }

    textColor = "white";

    switch (farm.ansvarligBrugerId) {
      case 20: // Magnus Timmermann
        border = "border: 6px solid blue;";
        break;
      case 2: // Glennie Christensen
        border = "border: 6px solid green;";
        break;
      case 24: // Rebecca Sachse
        border = "border: 6px solid red;";
        break;
      case 21: // Nina Jebens
        border = "border: 6px solid purple;";
        break;
      case 26: // Niels
        border = "border: 6px solid brown;";
        break;
      case 25: // Claus Elmann
        border = "border: 6px solid white;";
        break;
      default:
        break;
    }

    switch (farm.status) {
      case "Telekontakt":
        markerColor = "brown";
        break;
      case "Salgsmøde":
        markerColor = "blue";
        break;
      case "Praktik":
        markerColor = "orange";
        break;
      case "Kunde":
        markerColor = "pink";
        break;
      case "Afvist":
        markerColor = "grey";
        break;
      case "UgyldigeOplysninger":
        markerColor = "yellow";
        break;
      default:
        markerColor = "black";
        break;
    }

    if (border !== "") {
      width = width + 12;
      height = height + 12;
    }

    if (farm.hubspotCompanyId == this.hubspotCompanyId) {
      markerColor = "yellow";
      textColor = "black";
    }

    const markerIcon = this.createMarkerIcon(markerColor, border, textColor, width, height, farm.koer, selected);

    const marker = L.marker([farm.latitude || 0, farm.longitude || 0], {
      icon: markerIcon
    });

    marker.on("click", event => {
      if (event.originalEvent.ctrlKey) {
        const index = this.selectedMarkers.findIndex(selected => selected.hubspotCompanyId === farm.hubspotCompanyId);
        if (index === -1) {
          this.selectedMarkers.push(farm);
          this.toggleSelectMarker(farm.hubspotCompanyId, true);
        } else {
          // Hvis markøren allerede er valgt, fjern den fra de valgte markører
          this.selectedMarkers.splice(index, 1);
          this.toggleSelectMarker(farm.hubspotCompanyId, false); // Fjern markeringen
        }

        if (this.selectedMarkers.length === 2) {
          const start = [this.selectedMarkers[0].latitude, this.selectedMarkers[0].longitude];
          const end = [this.selectedMarkers[1].latitude, this.selectedMarkers[1].longitude];
          this.calculateDriveTime(start, end);
        }
      } else {
        this.openFarmDialog(farm);
      }
      this.cd.markForCheck();
    });

    return marker;
  }

  private addFarmMarkers() {
    // Clear existing markers
    this.map.eachLayer(layer => {
      if (layer instanceof L.Marker) {
        this.map.removeLayer(layer);
      }
    });

    if (this.filteredFarms.length == 0) {
      alert("Ingen data fundet");
      return console.log("No farms found");
    }

    this.markers = this.filteredFarms.map(farm => this.createMarker(farm, false));

    const markerGroup = L.featureGroup(this.markers).addTo(this.map);
    // if (this.hubspotCompanyId) this.zoomToCompany(this.hubspotCompanyId, 14);
    // else this.map.fitBounds(markerGroup.getBounds());

    setTimeout(() => {
      if (this.hubspotCompanyId) this.zoomToCompany(this.hubspotCompanyId, 14);
      else this.map.fitBounds(markerGroup.getBounds());
      //this.cd.markForCheck();
    }, 50);
  }

  public toggleSelectMarker(hubspotCompanyId: number, selected: boolean) {
    const farm = this.farms.find(f => f.hubspotCompanyId === hubspotCompanyId);
    if (farm) {
      const markerIndex = this.markers.findIndex(marker => marker.getLatLng().equals(farm.coordinates));
      if (markerIndex !== -1) {
        var tempMarker = this.createMarker(farm, selected);

        this.markers[markerIndex].setIcon(tempMarker.options.icon);

        this.cd.markForCheck();
      }
    }
  }

  private calculateDriveTime(start: number[], end: number[]) {
    const apiKey = "5b3ce3597851110001cf624830c6fcfd7c304712a2de5f5258d552f1";
    const url = `https://api.openrouteservice.org/v2/directions/driving-car?api_key=${apiKey}&start=${start[1]},${start[0]}&end=${end[1]},${end[0]}`;

    this.http.get(url).subscribe(
      (response: any) => {
        // Extract route geometry from the API response
        const geometry = response.features[0].geometry;

        // Create polyline using route geometry
        const polyline = L.polyline(
          geometry.coordinates.map(coord => [coord[1], coord[0]]),
          { color: "blue" }
        );

        // Additional processing if needed
        const summary = response.features[0].properties.summary;
        const distance = summary.distance / 1000; // Convert distance to kilometers
        const duration = summary.duration / 60; // Convert duration to minutes

        // Find the midpoint of the polyline
        const midpoint = geometry.coordinates[Math.floor(geometry.coordinates.length / 2)];

        // Create a custom icon for the label
        const labelIcon = L.divIcon({
          className: "polyline-label",
          html: `<div style="white-space: nowrap; background: white; padding: 2px 5px; border: 1px solid black; width: 150px;">${distance.toFixed(2)} km / ${duration.toFixed(2)} min</div>`
        });

        // Add the labelMarker to the map first
        const labelMarker = L.marker([midpoint[1], midpoint[0]], { icon: labelIcon }).addTo(this.map);

        // Set the z-index of the labelMarker
        labelMarker.setZIndexOffset(1000); // Adjust the value as needed

        // Add the polyline to the map
        polyline.addTo(this.map);

        const c1 = this.selectedMarkers[0].hubspotCompanyId;
        const c2 = this.selectedMarkers[1].hubspotCompanyId;

        // Add a click event listener to the labelMarker to remove polyline and labelMarker when clicked
        labelMarker.on("click", () => {
          this.map.removeLayer(polyline); // Remove polyline from the map
          this.map.removeLayer(labelMarker); // Remove labelMarker from the map
          this.toggleSelectMarker(c1, false);
          this.toggleSelectMarker(c2, false);
        });

        this.selectedMarkers = [];
        // const alertMessage = `Køretiden mellem de valgte punkter er ${duration.toFixed(2)} minutter, og afstanden er ${distance.toFixed(2)} kilometer.`;
        // alert(alertMessage);
      },
      error => {
        console.error("Fejl ved beregning af køretid:", error);
      }
    );
  }

  // private zoomToCompany(hubspotCompanyId: number, zoomLevel: number) {
  //   //console.log("Zooming to company:", hubspotCompanyId);
  //   const company = this.farms.find(farm => farm.hubspotCompanyId === hubspotCompanyId);
  //   //console.log("company:", company);
  //   if (company) {
  //     //console.log("setView:", company.coordinates);
  //     this.map.setView(company.coordinates, zoomLevel);
  //     this.cd.markForCheck();
  //   } else {
  //     console.error("Company not found for hubspotCompanyId:", hubspotCompanyId);
  //     // Alternativt: Vis en advarsel til brugeren
  //   }
  // }

  private zoomToCompany(hubspotCompanyId: number, zoomLevel: number) {
    const company = this.farms.find(farm => farm.hubspotCompanyId === hubspotCompanyId);

    if (company) {
      // Først skal du sikre dig, at kortet er korrekt opdateret
      this.map.invalidateSize();

      // Tilføj en kort forsinkelse for at sikre, at kortet har tid til at håndtere ændringer
      setTimeout(() => {
        this.map.setView(company.coordinates, zoomLevel, {
          animate: true, // Tilføj animation til zoomen
          duration: 0.5 // Varighed af animationen i sekunder
        });

        // Tving ændringsdetektion
        this.cd.detectChanges();
      }, 100); // 100ms forsinkelse, juster efter behov
    } else {
      console.error("Company not found for hubspotCompanyId:", hubspotCompanyId);
      // Alternativt: Vis en advarsel til brugeren
    }
  }

  public openInNamedWindow(event, windowName) {
    event.preventDefault(); // Forhindrer standard linkadfærd
    // Tjekker om det navngivne vindue allerede eksisterer
    if (windowName && windowName in window && !window[windowName].closed) {
      window[windowName].location.href = event.target.href; // Åbn linket i det navngivne vindue
    } else {
      window.open(event.target.href, windowName, "width=600,height=400"); // Åbn linket i et nyt vindue med det navngivne navn
    }
  }

  private loadFarms() {
    if (this.farms.length == 0) {
      // Kontroller om data allerede er hentet
      this.hubspotService
        .getHubSpotCompaniesForMap() // Hent alle virksomheder fra serveren uden filtrering
        .pipe(
          take(1),
          switchMap((data: HubspotCompanyMapDto[]) => {
            this.farms = data.map(item => ({
              ...item,
              name: item.brugerNavn || "N/A",
              coordinates: [item.latitude || 0, item.longitude || 0],
              isCustomer: !!item.hubspotCompanyId
            }));

            // Anvend filtrering på det lokale datasæt
            this.applyClientSideFilters();

            // Opdater farmsByRegion med antallet for hvert region
            // this.farmsByRegion = this.farms.reduce((acc, farm) => {
            //   acc[farm.region] = (acc[farm.region] || 0) + 1;
            //   return acc;
            // }, {});

            this.addFarmMarkers();
            //this.farmsLoaded = true; // Angiv at data er blevet indlæst
            return data;
          })
        )
        .subscribe();
    } else {
      this.applyClientSideFilters(); // Hvis data allerede er hentet, skal du kun anvende klientfiltrering
      this.addFarmMarkers(); // Opdater markørerne baseret på den nye filtrering
    }
  }

  private applyClientSideFilters() {
    this.filteredFarms = this.farms.filter(farm => {
      const userFilterPassed = this.selectedUserItems.map(item => item.value).includes(farm.ansvarligBrugerId);

      const statusItemsFilterPassed = this.selectedStatusItems.map(item => item.value).includes(farm.status);

      const mejeriFilterPassed = this.selectedMejeriItems.map(item => item.value).includes(farm.mejerier);

      const koerFilterPassed = farm.koer >= this.fraKoer && farm.koer <= this.tilKoer;

      //const skjulKunderFilterPassed = !this.skjulKunder || farm.customerId === 0;

      return userFilterPassed && statusItemsFilterPassed && mejeriFilterPassed && koerFilterPassed;
    });
  }

  public handleFilterChange() {
    this.loadFarms();
  }

  public openFarmDialog(farm: HubspotCompanyMapDto) {
    this.selectedFarm = farm;
    this.farmUserItem = this.userItems.find(user => user.value === farm.ansvarligBrugerId);
    this.farmStatusItem = this.statusFilterItems.find(stage => stage.value === farm.status);
    this.farmDialogVisible = true;
    this.cd.markForCheck();
  }

  handleCloseDialog(updatedCompany: HubspotCompanyDto | null) {
    //alert("Dialog lukket");
    if (updatedCompany) {
      //const selected = this.selectedMarkers.some(selected => selected.hubspotCompanyId === farm.hubspotCompanyId);

      const farm = this.farms.find(f => f.hubspotCompanyId === updatedCompany.hubspotCompanyId);

      farm.ansvarligBrugerId = updatedCompany.ansvarligBrugerId;
      farm.status = updatedCompany.status;

      // TODO fjer eller tilføj marker, hvis der er sket ændringer i om den er kunde eller ej

      const markerIndex = this.markers.findIndex(marker => marker.getLatLng().equals(farm.coordinates));
      if (markerIndex !== -1) {
        var tempMarker = this.createMarker(farm, false);

        //const newIcon = this.createMarkerIcon((farm.ansvarligBrugerId, farm.lifecyclestage, farm.koer, selected);

        this.markers[markerIndex].setIcon(tempMarker.options.icon);

        this.cd.markForCheck();
      }

      //const index = this.selectedMarkers.findIndex(selected => selected.hubspotCompanyId === farm.hubspotCompanyId);

      //alert("Dialog lukket - updatedCompany: " + updatedCompany.id);

      //this.toggleSelectMarker(updatedCompany.hubspotCompanyId, false);

      // const index = this.markers.findIndex(m => m.id === updatedCompany.id);
      // if (index !== -1) {
      //   this.markers[index] = updatedCompany;
      //   // Update the map marker here...
      // }
    }
    //else alert("Dialog lukket");

    this.farmDialogVisible = false;
  }

  // private updateMarkerColor(marker: L.Marker, farm: HubspotCompanyMapDto) {
  //   const selected = this.selectedMarkers.some(selected => selected.hubspotCompanyId === farm.hubspotCompanyId);
  //   const newIcon = this.createMarkerIcon(farm.ansvarligBrugerId, farm.lifecyclestage, farm.koer, selected);
  //   marker.setIcon(newIcon);
  // }

  public saveChanges() {
    this.selectedFarm.ansvarligBrugerId = +this.farmUserItem.value;
    this.selectedFarm.status = this.farmStatusItem.value;
    this.hubspotService.updateCompanyResponsiblePerson(this.selectedFarm).subscribe(() => {
      this.farmDialogVisible = false;
      this.cd.markForCheck();
    });
  }

  public createMail() {
    this.hubspotService.createMail(this.selectedFarm.id).subscribe(messageId => {
      let encodedMailId = encodeURIComponent(messageId.messageId);

      encodedMailId = encodedMailId.replace(/_/g, "%2B");

      const url = `https://outlook.office.com/mail/compose/${encodedMailId}`;

      console.log(url);

      window.open(url, "mailWindow");
    });
  }

  public createAppointment() {
    this.hubspotService.createAppointment(this.selectedFarm.id).subscribe(calendarId => {
      let encodedEventId = encodeURIComponent(calendarId.calendarId);

      encodedEventId = encodedEventId.replace(/_/g, "%2B");

      const url = `https://outlook.office.com/calendar/item/${encodedEventId}`;

      https: console.log(url);

      window.open(url, "mailWindow");
    });
  }

  // public dialogClosed() {
  //   alert("Dialog lukket");
  //   this.farmDialogVisible = false;
  //   this.cd.markForCheck();
  // }
}
