import { SortEvent } from "primeng/api";
import { Table } from "primeng/table";
import { ObjectUtils } from "primeng/utils";

export class PrimeNgTableColumn {
  /**
   * @param field The field that this column represents.
   * @param header The header to use for this column
   * @param exportable Wether the column can be exported or not. Default true. NOTE: This should only be used when you're using dynamic columns.
   * @param sortable Whether the column can be sorted. When enabled, a small sort-icon will also be added. Default true.
   * @param visible Wether the column is visible in view or not. Default true. NOTE: This should only be used when you're using dynamic columns.
   * @param sortField Used in a custom sort function to tell what other field that should be used when performing sorting of this column
   * @param isExpandToggle If true, an expand-icon button will be generated in the cell. The row data is provided for the "rowexpansion" template
   * @param isFlatData Tells if the field value to resolve is flat. This info is useful if the field name has one or more dots in its name
   * and we shouldn't treat it as nested data. Default true.
   * @param valueFormatter Custom format function for formatting the display value. Takes the whole row data as input.
   */
  constructor(
    public field: string,
    public header: string,
    public exportable = true,
    public sortable = true,
    public visible = true,
    public sortField?: string,
    public isExpandToggle = false,
    public isFlatData = true,
    public valueFormatter?: (rowData: any) => string
  ) {}
}

type ExportOptions = {
  selectionOnly?: boolean;
  fieldBoundary?: boolean;
  bom?: boolean;
};

export class PrimeNgUtilities {
  /**
   * Exports data of a table to a CSV file. Exports only the columns specified.
   * NOTE: nested fields for columns are supported (e.g. export of "some.nested.property").
   * @param table Instance of a Prime Ng table
   * @param exportColumns Array of the columns to export
   * @param options Options for the export.
   */
  public static exportCSV(table: Table, exportColumns: Array<PrimeNgTableColumn>, options?: ExportOptions) {
    let data: any[];
    let csv = "";

    const fieldBoundary = !options?.fieldBoundary ? "" : '"';

    if (options && options.selectionOnly) {
      data = table.selection || [];
    } else {
      data = table.filteredValue || table.value;

      if (table.frozenValue) {
        data = data ? [...table.frozenValue, ...data] : table.frozenValue;
      }
    }

    //headers
    for (let i = 0; i < exportColumns.length; i++) {
      const column = exportColumns[i];
      if (column.field) {
        csv += fieldBoundary + (column.header || column.field) + fieldBoundary;

        if (i < exportColumns.length - 1) {
          csv += table.csvSeparator;
        }
      }
    }

    //body
    data.forEach(record => {
      csv += "\n";
      for (let i = 0; i < exportColumns.length; i++) {
        const column = exportColumns[i];
        if (column.field) {
          let cellData = column.isFlatData ? record[column.field] : ObjectUtils.resolveFieldData(record, column.field);

          if (cellData != null) {
            if (table.exportFunction) {
              cellData = table.exportFunction({
                data: cellData,
                field: column.field,
                rowData: record
              });
            } else cellData = String(cellData).replace(/"/g, '""');
          } else cellData = "";

          csv += fieldBoundary + cellData + fieldBoundary;

          if (i < exportColumns.length - 1) {
            csv += table.csvSeparator;
          }
        }
      }
    });

    const bom = new Uint8Array(3);
    bom[0] = 239;
    bom[1] = 187;
    bom[2] = 191;

    const file = options && options.bom ? [bom, csv] : [csv];

    const blob = new Blob(file, {
      type: "text/csv;charset=utf-8;"
    });

    if ((window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(blob, table.exportFilename + ".csv");
    } else {
      const link = document.createElement("a");
      link.style.display = "none";
      document.body.appendChild(link);
      if (link.download !== undefined) {
        link.setAttribute("href", URL.createObjectURL(blob));
        link.setAttribute("download", table.exportFilename + ".csv");
        link.click();
      } else {
        csv = "data:text/csv;charset=utf-8," + csv;
        window.open(encodeURI(csv));
      }
      document.body.removeChild(link);
    }
  }

  /**
   * Custom sort function for Prime Ng tables. This is an extended version of the inbuild sorting works as we
   * here check for the "sortField" property of the column to sort by. Use this everytime a custom sort function is needed!
   */
  public static customSortFunction(event: SortEvent, customColumnDefinitions: Array<PrimeNgTableColumn>) {
    const col = customColumnDefinitions.find(f => f.field === event.field);
    let value1: any, value2: any;
    event.data.sort((data1, data2) => {
      if (col && col.sortField) {
        value1 = +data1[col.sortField];
        value2 = +data2[col.sortField];
      } else {
        value1 = data1[event.field];
        value2 = data2[event.field];
      }

      let result = null;
      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (typeof value1 === "string" && typeof value2 === "string") {
        result = value1.localeCompare(value2);
      } else {
        result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
      }
      return event.order * result;
    });
  }
}
