<div class="flex align-items-left">
  <p-dropdown optionLabel="label" dataKey="value" [options]="countries" [(ngModel)]="selectedCountryCode" (ngModelChange)="selectedCountryCodeChange($event)"> </p-dropdown>

  <!-- From Date -->
  <div class="col-12 sm:col-3 grid-nogutter">
    <label for="fromDate">Fra dato</label>
    <p-calendar [(ngModel)]="fromDate" dateFormat="dd/mm/yy" inputId="fromDate" (onSelect)="onDateFilterChanged()"></p-calendar>
  </div>

  <!-- To Date -->
  <div class="col-12 sm:col-3 grid-nogutter">
    <label for="fromDate">Til dato</label>
    <p-calendar [(ngModel)]="toDate" dateFormat="dd/mm/yy" inputId="toDate" (onSelect)="onDateFilterChanged()"></p-calendar>
  </div>
</div>

<div class="grid">
  <div class="col-12">
    <div class="card">
      <p-toast></p-toast>
      <p-table
        (sortFunction)="customSort($event)"
        [customSort]="true"
        #table
        [value]="emails$ | async"
        [columns]="columns$ | async"
        [globalFilterFields]="globalFilterFields$ | async"
        [loading]="loading"
        selectionMode="single"
        [(selection)]="selectedEmail"
        dataKey="id"
        (onRowSelect)="onRowSelect($event)"
        csvSeparator=";"
        [exportFilename]="'export'"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        responsiveLayout="scroll"
        currentPageReportTemplate="Viser {first} til {last} af {totalRecords} poster"
        [rowsPerPageOptions]="[10, 25, 50]"
      >
        <ng-template pTemplate="caption">
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 class="m-0">Emails</h5>

            <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help" (click)="exportToExcel()"></button>

            <!-- <button pButton pRipple label="Afsend ikke sendte" icon="pi pi-upload" class="p-button-help" (click)="sendEmails()"></button> -->

            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="table.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th></th>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ col.header }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
            <td>
              <div class="flex">
                <div>
                  <button *ngIf="rowData['hasAttachments']" pButton pRipple icon="pi pi-paperclip" class="p-button-rounded p-button-success mr-2" (click)="showAttachments(rowData)"></button>
                </div>
              </div>
            </td>
            <td *ngFor="let col of columns">
              {{ rowData[col.field] }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-dialog header="Email indhold" [(visible)]="displayEmailDialog" styleClass="" [style]="{ width: '50vw' }" [baseZIndex]="10000">
  <p id="print-section" class="mb-2 myEmailClass" [innerHtml]="text"></p>
  <ng-template pTemplate="footer">
    <button pButton class="p-ripple p-element p-button p-component" icon="pi pi-print" printSectionId="print-section" ngxPrint label="Print"></button>
    <p-button icon="pi pi-times" (click)="displayEmailDialog = false" label="luk" class="p-button-text"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="Vedhæftede filer" [(visible)]="displayEmailAttachmentsDialog" styleClass="" [style]="{ width: '50vw' }" [baseZIndex]="10000">
  <div *ngIf="loading; else content">Loading attachments...</div>
  <ng-template #content>
    <div *ngIf="attachments && attachments.length">
      <div *ngFor="let attachment of attachments" class="custom-button-wrapper">
        <button pButton type="button" class="mb-3" (click)="showAttachment(attachment.id, attachment.fileName)">
          {{ attachment.fileName }}
        </button>
      </div>
    </div>
    <div *ngIf="!attachments || attachments.length === 0">No attachments found.</div>
  </ng-template>
</p-dialog>

<p-dialog header="Email attachment" [modal]="true" [(visible)]="displayPdfDialog" [style]="{ width: '50vw', height: '98vh' }" [baseZIndex]="10000">
  <p-progressSpinner *ngIf="pdfSpinner"></p-progressSpinner>
  <div style="width: 100%; height: 100%">
    <ngx-extended-pdf-viewer *ngIf="pdfViewerVisible" [src]="src" [showOpenFileButton]="false" [showSidebarButton]="false" [useBrowserLocale]="true"></ngx-extended-pdf-viewer>
  </div>
</p-dialog>
