import { Injectable } from "@angular/core";

/**
 * Service for abstracting the Localstorage and Sessionstorage of browsers. Created in order to make code more testable in future.
 */
@Injectable({
  providedIn: "root"
})
export class BiLocalAndSessionStorageService {
  public setItem(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  public setSessionItem(key: string, data: any): void {
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  public getItem(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }

  public getSessionItem(key: string): any {
    return JSON.parse(sessionStorage.getItem(key));
  }

  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  public removeSessionItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  public clearLocal() {
    localStorage.clear();
  }

  public clearSession() {
    sessionStorage.clear();
  }
}
