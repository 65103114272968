<div *ngIf="isSuperAdmin" class="flex-container">
  <div class="p-field">
    <label for="input1">Vælg farm: </label>
    <p-dropdown optionLabel="label" dataKey="value" [options]="farmFilterItems" [(ngModel)]="selectedFarmItem" (ngModelChange)="initializeItems()"></p-dropdown>
  </div>
  <div *ngIf="selectedFarmItem.value !== 'Alle'">
    <!-- <textarea pInputTextarea [(ngModel)]="note" [autoResize]="true" [rows]="5" [cols]="30" placeholder="Paste data here"></textarea> -->

    <textarea pInputTextarea class="w-full" [(ngModel)]="note" name="note" rows="10"></textarea>

    <div class="button-container mt-5" *ngIf="note !== ''">
      <button pButton type="button" label="Læs" icon="pi pi-plus" (click)="parseValue()"></button>
    </div>
  </div>
</div>

<!-- <div class="flex align-items-left">
   <p-dropdown optionLabel="label" dataKey="value" [options]="countries" [(ngModel)]="selectedCountryCode" (ngModelChange)="selectedCountryCodeChange($event)"> </p-dropdown>
  <p-checkbox [binary]="true" class="cb-large ml-3" inputId="mapped" label="Vis endnu ikke oprettede leads" [ngModel]="showNotInHubspot" (ngModelChange)="onShowActiveChange($event)"></p-checkbox>
</div> -->

<div class="grid">
  <div class="col-12">
    <div class="card">
      <p-toast></p-toast>
      <p-table
        #table
        [value]="itemList$ | async"
        [columns]="columns$ | async"
        [globalFilterFields]="globalFilterFields$ | async"
        [loading]="loading"
        selectionMode="single"
        [(selection)]="selectedItem"
        dataKey="id"
        (onRowSelect)="onRowSelect($event)"
        csvSeparator=";"
        [exportFilename]="'export'"
        [paginator]="true"
        [rows]="25"
        [showCurrentPageReport]="true"
        responsiveLayout="scroll"
        currentPageReportTemplate="Viser {first} til {last} af {totalRecords} poster"
        [rowsPerPageOptions]="[25, 50, 100, 500]"
      >
        <ng-template pTemplate="caption">
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 class="m-0">Behandlings forløb</h5>

            <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help" (click)="exportToExcel()"></button>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="table.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">
              <!-- Check if the column should be sortable -->
              <span *ngIf="col.field !== 'area'; else noSort">
                <span [pSortableColumn]="col.field">{{ col.header }}</span>
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </span>
              <!-- If not sortable, just display the header text -->
              <ng-template #noSort>
                {{ col.header }}
              </ng-template>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
            <td *ngFor="let col of columns">
              <!-- Check if the current column field is the one for the visual 'area-container' -->
              <ng-container *ngIf="col.field === 'area'; else textTemplate">
                <span class="area-container">
                  <span class="area-row">
                    <span class="area" [ngClass]="{ 'selected-area': rowData['lf'] }"></span>
                    <span class="area" [ngClass]="{ 'selected-area': rowData['rf'] }"></span>
                  </span>
                  <span class="area-row">
                    <span class="area" [ngClass]="{ 'selected-area': rowData['lb'] }"></span>
                    <span class="area" [ngClass]="{ 'selected-area': rowData['rb'] }"></span>
                  </span>
                </span>
              </ng-container>
              <ng-template #textTemplate>
                {{ rowData[col.field] }}
              </ng-template>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-dialog
  *ngIf="dialogVisible"
  [header]="'Behandlinger - ko: ' + selectedItem?.cowNumber + ' - ' + selectedItem.diagnose + ' - ' + selectedItem.anatomy"
  [(visible)]="dialogVisible"
  [modal]="true"
  (onHide)="handleCloseDialog(null)"
  [style]="{ width: '90rem', minHeight: '90vh' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [maximizable]="true"
>
  <p-table [value]="selectedItem?.treatments" [paginator]="true" [rows]="50" responsiveLayout="scroll">
    <ng-template pTemplate="header">
      <tr>
        <th>Dato Start</th>
        <th>Ydelse</th>
        <th>Varighed (sekunder)</th>
        <th>Område</th>
        <th>Lf</th>
        <th>Rf</th>
        <th>Lb</th>
        <th>Rb</th>
        <th>Kommentarer</th>
        <th>Billeder</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-treatment>
      <tr>
        <td>{{ treatment.dateStartUtc | date: "dd-MM-yyyy hh:mm:ss" }}</td>
        <td>{{ treatment.dailyYield }}</td>
        <td>{{ treatment.durationSeconds }}</td>

        <td>
          <span class="area-container">
            <span class="area-row">
              <span class="area" [ngClass]="{ 'selected-area': treatment.lf }"></span>
              <span class="area" [ngClass]="{ 'selected-area': treatment.rf }"></span>
            </span>
            <span class="area-row">
              <span class="area" [ngClass]="{ 'selected-area': treatment.lb }"></span>
              <span class="area" [ngClass]="{ 'selected-area': treatment.rb }"></span>
            </span>
          </span>
        </td>

        <td>{{ treatment.sccLf ? " " + treatment.sccLf * 1000 : "" }}</td>
        <td>{{ treatment.sccRf ? " " + treatment.sccRf * 1000 : "" }}</td>
        <td>{{ treatment.sccLb ? " " + treatment.sccLb * 1000 : "" }}</td>
        <td>{{ treatment.sccRb ? " " + treatment.sccRb * 1000 : "" }}</td>
        <td>{{ treatment.comments }}</td>
        <td>{{ treatment.documents.length }}</td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>
