import { Component, Input, Output, EventEmitter } from "@angular/core";
import { HubspotCompanyDto } from "@apiModels/hubspotCompanyDto";

@Component({
  selector: "app-prospect-detail",
  templateUrl: "./prospect-detail.component.html",
  styleUrls: ["./prospect-detail.component.scss"]
})
export class ProspectDetailsComponent {
  @Input() hubspotCompanyId: number;
  @Output() closeDialog = new EventEmitter<HubspotCompanyDto | null>();

  handleCloseDialog(company: HubspotCompanyDto | null) {
    this.closeDialog.emit(company);
  }

  onHide() {
    this.closeDialog.emit(null);
  }
}
