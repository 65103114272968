<div class="flex align-items-left">
  <div class="col-12 sm:col-3 grid-nogutter">
    <label for="fromDate"><b>REVISION FILTER:</b></label>
    <p-dropdown class="ml-3 mr-3" optionLabel="label" dataKey="value" [options]="wikiRevisionFilterItems" [(ngModel)]="selectedWikiRevisionItem" (ngModelChange)="selectedTilbudRevisionItemChange()">
    </p-dropdown>
  </div>
</div>

<div class="grid">
  <div class="col-12">
    <div class="card">
      <p-toast></p-toast>
      <p-table
        #table
        [value]="itemList$ | async"
        [columns]="columns$ | async"
        [globalFilterFields]="globalFilterFields$ | async"
        [loading]="loading"
        selectionMode="single"
        [(selection)]="selectedItem"
        dataKey="id"
        [sortField]="sortField"
        [sortOrder]="sortOrder"
        (onRowSelect)="onRowSelect($event)"
        csvSeparator=";"
        [exportFilename]="'export'"
        [paginator]="true"
        [rows]="25"
        [showCurrentPageReport]="true"
        responsiveLayout="scroll"
        currentPageReportTemplate="Viser {first} til {last} af {totalRecords} poster"
        [rowsPerPageOptions]="[25, 50, 100, 500]"
      >
        <ng-template pTemplate="caption">
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 class="m-0">Wiki</h5>

            <button pButton pRipple label="Opret ny WIKI" icon="pi pi-plus" class="p-button-success mr-2" (click)="onCreateNewWiki()"></button>

            <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help" (click)="exportToExcel()"></button>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="table.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ col.header }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
            <td *ngFor="let col of columns" [innerHtml]="rowData[col.field]" [pTooltip]="col.toolTip ? rowData[col.toolTip] : ''" tooltipPosition="top"></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-confirmDialog header="Bekræft sletning" icon="pi pi-exclamation-triangle" [baseZIndex]="10000"></p-confirmDialog>

<p-dialog
  *ngIf="dialogVisible"
  [header]="selectedItem?.title ? 'Rediger Wiki' : 'Ny Wiki'"
  [(visible)]="dialogVisible"
  [modal]="true"
  (onHide)="handleCloseDialog()"
  [style]="{ width: '90%', minHeight: '90vh' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [maximizable]="true"
>
  <ng-container *ngIf="selectedItem">
    <form #wikiForm="ngForm">
      <div class="p-fluid">
        <div class="p-field">
          <label for="title" class="bold-label">Titel</label>
          <input id="title" type="text" pInputText [(ngModel)]="selectedItem.title" name="title" required #title="ngModel" />
          <div *ngIf="title.invalid && (title.dirty || title.touched)" class="p-error block">
            <div *ngIf="title.errors?.required">Titel er påkrævet.</div>
          </div>
        </div>
        <div class="p-field">
          <label for="content" class="bold-label">Indhold</label>
          <textarea id="content" placeholder="Skriv en wiki" rows="20" pInputTextarea [(ngModel)]="selectedItem.content" name="content" required #content="ngModel"></textarea>
          <div *ngIf="content.invalid && (content.dirty || content.touched)" class="p-error block">
            <div *ngIf="content.errors?.required">Indhold er påkrævet.</div>
          </div>
        </div>
      </div>
      <div class="p-dialog-footer">
        <button type="button" pButton label="Gem" icon="pi pi-check" class="p-button-raised" (click)="saveChanges()" [disabled]="!wikiForm.valid"></button>
        <button type="button" pButton label="Annuller" icon="pi pi-times" class="p-button-outlined p-button-secondary" (click)="handleCloseDialog()"></button>
        <button type="button" pButton label="Slet" icon="pi pi-trash" class="p-button-danger" [disabled]="!wikiForm.valid" (click)="deleteItem()"></button>
      </div>
    </form>
  </ng-container>
</p-dialog>
