import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HubspotCompanyModel } from "@apiModels/hubspotCompanyModel";
import { HubspotService } from "@globals/services/hubspot.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { PrimeNgUtilities } from "@shared/variables-and-functions/primeNg-utilities";
import { ConfirmationService, MessageService } from "primeng/api";
import { Table } from "primeng/table";
import { Observable, finalize } from "rxjs";

@UntilDestroy()
@Component({
  templateUrl: "./hubspot-companies-list.component.html",
  providers: [MessageService, ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HubspotCompaniesListComponent implements OnInit {
  @ViewChild("table") table: Table;

  public loading = true;

  public prospects: Array<HubspotCompanyModel> = [];
  public prospects$: Observable<Array<HubspotCompanyModel>>;

  selectedValue: string;

  selectedProspect: HubspotCompanyModel;

  cols: any[];

  showNotInHubspot: boolean;

  public selectedFarmId: number;
  public farmDialogVisible = false;

  constructor(
    public hubspotService: HubspotService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.showNotInHubspot = false;

    this.initializeProspects();

    this.cols = [
      { field: "id", header: "Id" },
      { field: "chrNr", header: "ChrNr" },
      { field: "besNr", header: "BesNr" },
      { field: "ejerNavn", header: "EjerNavn" },
      { field: "cvrNummer", header: "CvrNummer" },
      { field: "adresse", header: "Adresse" },
      { field: "postNummer", header: "PostNr" },
      { field: "postDistrikt", header: "Post Dist" },
      { field: "byNavn", header: "ByNavn" },
      { field: "besaetningstype", header: "Besætningstype" },
      // { field: "contactName", header: "Kontakt" },
      // { field: "contactPhone", header: "Telefon" },
      // { field: "contactEmail", header: "Email" },
      { field: "koer", header: "Køer" },
      { field: "kvier", header: "Kvier" },
      { field: "handyr", header: "Handyr" },
      { field: "region", header: "Region" },
      { field: "team", header: "Team" }
    ];
  }

  onRowSelect(event) {
    //   this.router.navigate([event.data.id, "main"], { relativeTo: this.activeRoute });
  }

  public openFarmDialog(farm: HubspotCompanyModel) {
    this.selectedFarmId = farm.id;
    this.farmDialogVisible = true;

    // this.selectedFarm = farm;
    // this.farmUserItem = this.userItems.find(user => user.value === farm.ansvarligBrugerId);
    // this.farmLeadStatusItem = this.leadStatusItems.find(stage => stage.value === farm.lifecyclestage);
    // this.farmDialogVisible = true;
    this.cd.markForCheck();
  }

  // public onCreateNewProspect() {
  //   this.router.navigate([0, "main"], { relativeTo: this.activeRoute });
  // }

  public onShowActiveChange(checked: boolean) {
    if (checked) {
      this.showNotInHubspot = true;
      this.initializeProspects();
    } else {
      this.showNotInHubspot = false;
      this.initializeProspects();
    }
  }

  private initializeProspects() {
    this.prospects$ = this.hubspotService.getHubSpotCompanies(this.showNotInHubspot).pipe(
      untilDestroyed(this),
      finalize(() => (this.loading = false))
    );
  }

  // sendMail(messageId: string) {
  //   if (messageId) {
  //     this.prospectService
  //       .sendMail(+messageId)
  //       .pipe(take(1))
  //       .subscribe(c => {
  //         console.log(c);
  //       });
  //   }
  // }

  public exportToExcel() {
    PrimeNgUtilities.exportCSV(this.table, this.cols, { bom: true });
  }

  showOnMap(item: HubspotCompanyModel) {
    this.router.navigate(["/ansvarkort"], { queryParams: { id: item.hubspotCompanyId } });
  }
}
