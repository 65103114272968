import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { UserModel } from "@apiModels/userModel";
import { UserService } from "@globals/services/user.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ConfirmationService, MessageService } from "primeng/api";
import { Observable, finalize } from "rxjs";
//import { CustomerModel } from "@apiModels/customerModel";
import { ActivatedRoute, Router } from "@angular/router";
import { PrimeNgUtilities } from "@shared/variables-and-functions/primeNg-utilities";
import { Table } from "primeng/table";

@UntilDestroy()
@Component({
  selector: "app-user-list",
  templateUrl: "./superadmin-customer-userlist.component.html",
  providers: [MessageService, ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuperAdminCustomerUserListComponent implements OnInit {
  @ViewChild("table") table: Table;

  public loading = true;
  private customerId: number;
  public users: Array<UserModel> = [];
  public users$: Observable<Array<UserModel>>;

  selectedUser: UserModel;

  cols: any[];

  showDeleted: boolean;

  constructor(
    private userService: UserService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.showDeleted = false;

    this.initializeUsers();

    this.cols = [
      { field: "email", header: "Email" },
      { field: "name", header: "Navn" },
      { field: "phone", header: "Telefon" }
    ];
  }

  onRowSelect(event) {
    this.router.navigate(["users", event.data.id, "edit"]);
  }

  public onAddNewUserClick() {
    const routeParams: { [key: string]: string | number } = {
      customerId: this.customerId
    };

    this.router.navigate(["users", 0, "edit", routeParams]);
  }

  public onShowActiveChange(checked: boolean) {
    if (checked) {
      this.showDeleted = true;
      this.initializeUsers();
    } else {
      this.showDeleted = false;
      this.initializeUsers();
    }
  }

  private initializeUsers() {
    this.customerId = this.activeRoute.parent.snapshot.params.id;
    this.users$ = this.userService.getUsersByCustomer(this.customerId, this.showDeleted).pipe(
      untilDestroyed(this),
      finalize(() => (this.loading = false))
    );
  }

  public exportToExcel() {
    PrimeNgUtilities.exportCSV(this.table, this.cols, { bom: true });
  }
}
