import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BenefitCalculationDto } from "@apiModels/benefitCalculationDto";
import { HubspotCompanyDto } from "@apiModels/hubspotCompanyDto";
import { HubspotCompanyMapDto } from "@apiModels/hubspotCompanyMapDto";
import { HubspotCompanyModel } from "@apiModels/hubspotCompanyModel";
import { ProspectNoteDto } from "@apiModels/prospectNoteDto";
import { ApiRoutes } from "@shared/classes/ApiRoutes";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class HubspotService {
  constructor(private http: HttpClient) {}

  public getHubSpotCompaniesByTeam(team: string, fraKoer?: number, tilKoer?: number): Observable<HubspotCompanyMapDto[]> {
    const params: { [key: string]: string } = {
      team: team,
      fraKoer: fraKoer?.toString(),
      tilKoer: tilKoer?.toString()
    };

    return this.http.get<Array<HubspotCompanyMapDto>>(ApiRoutes.hubspotRoutes.get.getHubSpotCompaniesByTeam, { params: params });
  }

  public getHubSpotCompaniesByRegion(region: string, fraKoer?: number, tilKoer?: number): Observable<HubspotCompanyMapDto[]> {
    const params: { [key: string]: string } = {
      region: region,
      fraKoer: fraKoer?.toString(),
      tilKoer: tilKoer?.toString()
    };

    return this.http.get<Array<HubspotCompanyMapDto>>(ApiRoutes.hubspotRoutes.get.getHubSpotCompaniesByRegion, { params: params });
  }

  public getHubSpotCompaniesForMap(): Observable<HubspotCompanyMapDto[]> {
    const params: { [key: string]: string } = {};

    return this.http.get<Array<HubspotCompanyMapDto>>(ApiRoutes.hubspotRoutes.get.getHubSpotCompaniesForMap, { params: params });
  }

  public getHubSpotCompanies(onlyNotInHubspot: boolean): Observable<HubspotCompanyModel[]> {
    const params: { [key: string]: string } = { onlyNotInHubspot: onlyNotInHubspot ? "true" : "false" };
    return this.http.get<Array<HubspotCompanyModel>>(ApiRoutes.hubspotRoutes.get.getHubSpotCompanies, { params: params });
  }

  public updateCompanyResponsiblePerson(dto: HubspotCompanyMapDto): Observable<any> {
    return this.http.post<any>(ApiRoutes.hubspotRoutes.update.updateCompanyResponsiblePerson, dto);
  }

  public updateHubspotCompany(dto: HubspotCompanyDto): Observable<any> {
    return this.http.post<any>(ApiRoutes.hubspotRoutes.update.updateHubspotCompany, dto);
  }

  public createMail(hubspotCompanyId: number): Observable<any> {
    return this.http.post<string>(ApiRoutes.hubspotRoutes.post.createMail, hubspotCompanyId);
  }

  public createAppointment(hubspotCompanyId: number): Observable<any> {
    return this.http.post<string>(ApiRoutes.hubspotRoutes.post.createAppointment, hubspotCompanyId);
  }

  public getHubspotCompanyDtoFromId(id: number): Observable<HubspotCompanyDto> {
    const params: { [key: string]: string } = { id: id.toString() };
    return this.http.get<HubspotCompanyDto>(ApiRoutes.hubspotRoutes.get.getHubspotCompanyDtoFromId, { params: params });
  }

  public createCustomer(dto: HubspotCompanyMapDto): Observable<any> {
    return this.http.post<string>(ApiRoutes.hubspotRoutes.post.createCustomer, dto);
  }

  public getProspectNotesByCompanyId(hubspotCompanyId: number): Observable<ProspectNoteDto[]> {
    const params: { [key: string]: string } = {
      hubspotCompanyId: hubspotCompanyId.toString()
    };

    return this.http
      .get<ProspectNoteDto[]>(ApiRoutes.hubspotRoutes.get.getProspectNotesByCompanyId, {
        params: params
      })
      .pipe(
        map(emails => {
          return emails;
        })
      );
  }

  public saveProspectNote(dto: ProspectNoteDto): Observable<any> {
    return this.http.post<ProspectNoteDto>(ApiRoutes.hubspotRoutes.post.saveProspectNote, dto);
  }

  public downloadExcelBenefitCalculation(prospectId: number) {
    return this.http.get(ApiRoutes.hubspotRoutes.get.downloadExcelBenefitCalculation, {
      params: { prospectId },
      responseType: "arraybuffer"
    });
  }

  public getBenefitCalculationFromHubspotCompanyId(id: number): Observable<BenefitCalculationDto> {
    const params: { [key: string]: string } = { id: id.toString() };
    return this.http.get<BenefitCalculationDto>(ApiRoutes.hubspotRoutes.get.getBenefitCalculationFromHubspotCompanyId, { params: params });
  }

  public saveBenefitCalculation(dto: BenefitCalculationDto): Observable<any> {
    return this.http.post<BenefitCalculationDto>(ApiRoutes.hubspotRoutes.post.saveBenefitCalculation, dto);
  }

  public getProspectNotes(type: string): Observable<ProspectNoteDto[]> {
    const params: { [key: string]: string } = { type: type };

    return this.http
      .get<ProspectNoteDto[]>(ApiRoutes.hubspotRoutes.get.getProspectNotes, {
        params: params
      })
      .pipe(
        map(items => {
          return items;
        })
      );
  }

  public getBenefitCalculations(): Observable<BenefitCalculationDto[]> {
    const params: { [key: string]: string } = {};

    return this.http
      .get<BenefitCalculationDto[]>(ApiRoutes.hubspotRoutes.get.getBenefitCalculations, {
        params: params
      })
      .pipe(
        map(items => {
          return items;
        })
      );
  }
}
