import { SelectItem } from "primeng/api";

// Define user-specific items
export const userItems: SelectItem[] = [
  { label: "Glennie Christensen", value: 2 },
  { label: "Magnus Timmermann", value: 20 },
  { label: "Nina Jebens", value: 21 },
  { label: "Rebecca Sachse", value: 24 },
  { label: "Claus Elmann", value: 25 },
  { label: "Niels Schultz", value: 26 },
  { label: "Salg(kalender test)", value: 1004 }
];

// Define user-specific items with "Alle" option
export const userItemsWithAll: SelectItem[] = [{ label: "Alle", value: -1 }, ...userItems];

// Define user-specific items with "None" option
export const userItemsWithAllAndNone: SelectItem[] = [{ label: "Ingen", value: -2 }, ...userItemsWithAll];

export const userItemsWithNotSelected: SelectItem[] = [{ label: "Ikke valgt", value: 0 }, ...userItems];

export const statusFilterItems: SelectItem[] = [
  { value: "", label: "Ikke angivet" },
  { value: "Telekontakt", label: "Telekontakt" },
  { value: "Salgsmøde", label: "Salgsmøde" },
  { value: "Praktik", label: "Praktik" },
  { value: "Kunde", label: "Kunde" },
  { value: "Afvist", label: "Afvist" },
  { value: "UgyldigeOplysninger", label: "Ugyldige kontaktoplysninger" }
];
