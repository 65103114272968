<form *ngIf="mainForm" [formGroup]="mainForm">
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="p-fluid p-formgrid grid">
          <div class="field col-12 md:col-3">
            <label for="cows" class="block bold-label">Køer</label>
            <input formControlName="cows" type="number" pInputText id="cows" aria-describedby="cows-help" autocomplete="one-time-code" />
            <div *ngIf="mainForm.controls['cows'].errors && (mainForm.controls['cows'].dirty || mainForm.controls['cows'].touched)" class="p-error block">
              <div *ngIf="mainForm.controls['cows'].errors?.required">Påkrævet</div>
              <div *ngIf="mainForm.controls['cows'].errors?.digitsOnly">Kun hele tal</div>
            </div>
          </div>

          <div class="field col-12 md:col-3">
            <label for="mastitisPercent" class="block bold-label">Mastitis Procent</label>
            <input formControlName="mastitisPercent" type="number" pInputText id="mastitisPercent" autocomplete="off" />
            <div *ngIf="mainForm.controls['mastitisPercent'].errors && (mainForm.controls['mastitisPercent'].dirty || mainForm.controls['mastitisPercent'].touched)" class="p-error block">
              <div *ngIf="mainForm.controls['mastitisPercent'].errors?.required">Påkrævet</div>
              <div *ngIf="mainForm.controls['mastitisPercent'].errors?.digitsOnly">Kun hele tal</div>
            </div>
          </div>

          <div class="field col-12 md:col-3">
            <label for="expensePerSickCow" class="block bold-label">Udgift pr. syg ko</label>
            <input formControlName="expensePerSickCow" type="number" pInputText id="expensePerSickCow" autocomplete="off" />
            <div *ngIf="mainForm.controls['expensePerSickCow'].errors && (mainForm.controls['expensePerSickCow'].dirty || mainForm.controls['expensePerSickCow'].touched)" class="p-error block">
              <div *ngIf="mainForm.controls['expensePerSickCow'].errors?.required">Påkrævet</div>
              <div *ngIf="mainForm.controls['expensePerSickCow'].errors?.digitsOnly">Kun hele tal</div>
            </div>
          </div>

          <div class="field col-12 md:col-3">
            <label for="pricePrCow" class="block bold-label">Pris pr. ko</label>
            <input formControlName="pricePrCow" type="number" pInputText id="pricePrCow" autocomplete="off" />
            <div *ngIf="mainForm.controls['pricePrCow'].errors && (mainForm.controls['pricePrCow'].dirty || mainForm.controls['pricePrCow'].touched)" class="p-error block">
              <div *ngIf="mainForm.controls['pricePrCow'].errors?.required">Påkrævet</div>
              <div *ngIf="mainForm.controls['pricePrCow'].errors?.digitsOnly">Kun hele tal</div>
            </div>
          </div>

          <div class="field col-12 md:col-3">
            <label for="milkDays" class="block bold-label">Mælke dage</label>
            <input formControlName="milkDays" type="number" pInputText id="milkDays" autocomplete="off" />
            <div *ngIf="mainForm.controls['milkDays'].errors && (mainForm.controls['milkDays'].dirty || mainForm.controls['milkDays'].touched)" class="p-error block">
              <div *ngIf="mainForm.controls['milkDays'].errors?.required">Påkrævet</div>
              <div *ngIf="mainForm.controls['milkDays'].errors?.digitsOnly">Kun hele tal</div>
            </div>
          </div>

          <div class="field col-12 md:col-3">
            <label for="milkPerDay" class="block bold-label">Mælk pr. dag</label>
            <input formControlName="milkPerDay" type="number" pInputText id="milkPerDay" autocomplete="off" />
            <div *ngIf="mainForm.controls['milkPerDay'].errors && (mainForm.controls['milkPerDay'].dirty || mainForm.controls['milkPerDay'].touched)" class="p-error block">
              <div *ngIf="mainForm.controls['milkPerDay'].errors?.required">Påkrævet</div>
              <div *ngIf="mainForm.controls['milkPerDay'].errors?.digitsOnly">Kun hele tal</div>
            </div>
          </div>

          <div class="field col-12 md:col-3">
            <label for="dkkPerL" class="block bold-label">DKK pr. L</label>
            <input formControlName="dkkPerL" type="number" pInputText id="dkkPerL" autocomplete="off" />
            <div *ngIf="mainForm.controls['dkkPerL'].errors && (mainForm.controls['dkkPerL'].dirty || mainForm.controls['dkkPerL'].touched)" class="p-error block">
              <div *ngIf="mainForm.controls['dkkPerL'].errors?.required">Påkrævet</div>
              <div *ngIf="mainForm.controls['dkkPerL'].errors?.digitsOnly">Kun hele tal</div>
            </div>
          </div>

          <div class="field col-12 md:col-3">
            <label for="monthInAverage" class="block bold-label">Måned i gennemsnit</label>
            <input formControlName="monthInAverage" type="number" pInputText id="monthInAverage" autocomplete="off" />
            <div *ngIf="mainForm.controls['monthInAverage'].errors && (mainForm.controls['monthInAverage'].dirty || mainForm.controls['monthInAverage'].touched)" class="p-error block">
              <div *ngIf="mainForm.controls['monthInAverage'].errors?.required">Påkrævet</div>
              <div *ngIf="mainForm.controls['monthInAverage'].errors?.digitsOnly">Kun hele tal</div>
            </div>
          </div>

          <div class="field col-12 md:col-3">
            <label for="replacementRatePercent" class="block bold-label">Udskiftningsprocent</label>
            <input formControlName="replacementRatePercent" type="number" pInputText id="replacementRatePercent" autocomplete="off" />
            <div
              *ngIf="mainForm.controls['replacementRatePercent'].errors && (mainForm.controls['replacementRatePercent'].dirty || mainForm.controls['replacementRatePercent'].touched)"
              class="p-error block"
            >
              <div *ngIf="mainForm.controls['replacementRatePercent'].errors?.required">Påkrævet</div>
              <div *ngIf="mainForm.controls['replacementRatePercent'].errors?.digitsOnly">Kun hele tal</div>
            </div>
          </div>

          <div class="field col-12 md:col-3">
            <label for="reducedReplacementRatePercent" class="block bold-label">Reduceret udskiftningsprocent</label>
            <input formControlName="reducedReplacementRatePercent" type="number" pInputText id="reducedReplacementRatePercent" autocomplete="off" />
            <div
              *ngIf="
                mainForm.controls['reducedReplacementRatePercent'].errors && (mainForm.controls['reducedReplacementRatePercent'].dirty || mainForm.controls['reducedReplacementRatePercent'].touched)
              "
              class="p-error block"
            >
              <div *ngIf="mainForm.controls['reducedReplacementRatePercent'].errors?.required">Påkrævet</div>
              <div *ngIf="mainForm.controls['reducedReplacementRatePercent'].errors?.digitsOnly">Kun hele tal</div>
            </div>
          </div>

          <div class="field col-12 md:col-12">
            <label for="comment" class="block bold-label">Noter</label>
            <textarea id="comment" formControlName="comment" type="text" aria-describedby="comment-help" placeholder="Angiv noter" [rows]="3" pInputTextarea autoResize="autoResize"></textarea>
            <div *ngIf="comment.errors && (comment.dirty || comment.touched)" class="p-error block"></div>
          </div>

          <div class="field col-12">
            <div class="formgroup-inline">
              <div class="field">
                <button
                  pButton
                  pRipple
                  type="button"
                  [label]="'Gem/Beregn'"
                  class="p-button-raised"
                  [title]="mainForm.valid ? 'Gem dine indtastede data' : 'Deaktiveret, indtil formulardataene er gyldige'"
                  [disabled]="mainForm.pristine"
                  (click)="saveItem()"
                ></button>
              </div>
              <div class="field">
                <button
                  class="ml-3"
                  [disabled]="!benefitCalculation || !benefitCalculation.id > 0"
                  pButton
                  type="button"
                  [label]="'Download som excel' | translate"
                  (click)="downloadExcelBenefitCalculation()"
                ></button>
              </div>
            </div>
            <!-- <div class="field col-12">
              <div *ngIf="userId === 25" class="field">
                <button pButton pRipple class="p-button-outlined p-button-secondary" type="button" label="Test form for validation in console" (click)="getFormValidationErrors()"></button>
              </div>
              <br />Dirty: {{ mainForm.dirty }} <br />Touched: {{ mainForm.touched }}<br />Pristine: {{ mainForm.pristine }} <br />Valid: {{ mainForm.valid }} <br />Values:
              {{ mainForm.value | json }}
              <p class="field-error" [@fadeIn] *ngIf="showFormErrorMessage" translate>errorMessages.OneOrMoreFieldsMissing</p>
            </div> -->
          </div>
        </div>
      </div>

      <div *ngIf="benefitCalculation?.jpgBase64" class="card">
        <img #logo name="logo" [src]="imageUrl$ | async" alt="logo here" class="logo-image" />
      </div>
    </div>
  </div>
  <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [style]="{ width: '50vw' }" [baseZIndex]="10000"></p-confirmDialog>
  <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
</form>
