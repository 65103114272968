import { NgModule } from "@angular/core";
import { BiToBrTagsPipe } from "./bi-to-br-tags.pipe";
import { ZeroToEmptyPipe } from "./zero-to-empty.pipe";
import { BiNumberPipe } from "./bi-number.pipe";

@NgModule({
  declarations: [BiToBrTagsPipe, ZeroToEmptyPipe, BiNumberPipe],
  exports: [BiToBrTagsPipe, ZeroToEmptyPipe, BiNumberPipe]
})
export class ElPipesModule {}
