import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { BenefitCalculationDto } from "@apiModels/benefitCalculationDto";
import { BiLocalizationHelperService } from "@core/utility-services/bi-localization-helper.service";
import { BenefitCalculationDtoExt } from "@globals/ExtModels/BenefitCalculationDtoExt";
import { HubspotService } from "@globals/services/hubspot.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TableColumnPrimeNgExt } from "@shared/interfaces-and-enums/TableColumnPrimeNgExt";
import { PrimeNgTableColumn, PrimeNgUtilities } from "@shared/variables-and-functions/primeNg-utilities";
import moment from "moment";
import { ConfirmationService, MessageService } from "primeng/api";
import { Table } from "primeng/table";
import { Observable, ReplaySubject, finalize, map, tap } from "rxjs";

@UntilDestroy()
@Component({
  templateUrl: "./benefit-calculation-list.component.html",
  providers: [MessageService, ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BenefitCalculationListComponent implements OnInit {
  @ViewChild("table") table: Table;

  public loading = true;

  public itemList: Array<BenefitCalculationDto> = [];
  public itemList$: Observable<Array<BenefitCalculationDto>>;
  private columns = new ReplaySubject<Array<TableColumnPrimeNgExt>>(1);
  public columns$ = this.columns.asObservable();
  private globalFilterFields = new ReplaySubject<Array<string>>(1);
  public globalFilterFields$ = this.globalFilterFields.asObservable();
  selectedValue: string;

  public selectedItem: BenefitCalculationDtoExt | null = null;

  //cols: any[];

  //showNotInHubspot: boolean;

  // public selectedFarmId: number;
  public dialogVisible = false;

  constructor(
    public hubspotService: HubspotService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    private localizor: BiLocalizationHelperService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    //this.showNotInHubspot = false;

    this.initColumns();
    this.initializeItems();
  }

  private initColumns() {
    this.globalFilterFields.next(["body"]);
    this.columns.next([
      { field: "dateCreated", header: "Oprettet", sortField: "dateCreatedForSort" },
      { field: "prospectName", header: "Navn" },
      { field: "user", header: "Bruger" },
      { field: "cows", header: "Køer" },
      { field: "mastitisPercent", header: "Yver betændelses %" },
      { field: "directSavings", header: "Direkte besparelse" },
      { field: "potentialMilkRevenue", header: "Pot mælkeindtægt" },
      { field: "potentialLowerCO2Tax", header: "Pot lavere CO2 afgift" },
      { field: "benefitSum", header: "Sum" },
      { field: "comment", header: "Noter" }
    ]);
  }

  onRowSelect(event) {
    console.log("onRowSelect", event);
    this.selectedItem = event.data;
    this.dialogVisible = true;
    this.cd.detectChanges();
  }

  handleCloseDialog() {
    this.dialogVisible = false;
    this.initializeItems();
  }

  public initializeItems() {
    this.itemList$ = this.hubspotService.getBenefitCalculations().pipe(
      tap((data: Array<BenefitCalculationDtoExt>) => {
        data.forEach(element => {
          element.dateCreated = this.localizor.localizeDateTime(element.dateCreatedUtc);
          element.dateCreatedForSort = moment(element.dateCreatedUtc);
        });
      }),
      untilDestroyed(this),
      finalize(() => {
        this.loading = false;
      })
    );
  }

  // sanitizeHtml(html: string): SafeHtml {
  //   return this.sanitizer.bypassSecurityTrustHtml(html);
  // }

  // sendMail(messageId: string) {
  //   if (messageId) {
  //     this.prospectService
  //       .sendMail(+messageId)
  //       .pipe(take(1))
  //       .subscribe(c => {
  //         console.log(c);
  //       });
  //   }
  // }

  // public exportToExcel() {
  //   this.columns$.subscribe(columnsArray => {
  //     // Type assertion to cast TableColumnPrimeNgExt[] to PrimeNgTableColumn[]
  //     const primeNgColumns: Array<PrimeNgTableColumn> = columnsArray as Array<PrimeNgTableColumn>;
  //     PrimeNgUtilities.exportCSV(this.table, primeNgColumns, { bom: true });
  //   });
  // }

  public exportToExcel() {
    this.columns$.pipe(map(columnsArray => columnsArray.filter(col => !col.noExport))).subscribe(exportableColumns => {
      PrimeNgUtilities.exportCSV(this.table, exportableColumns as PrimeNgTableColumn[], { bom: true });
    });
  }

  // showOnMap(item: HubspotCompanyModel) {
  //   this.router.navigate(["/ansvarkort"], { queryParams: { id: item.hubspotCompanyId } });
  // }
}
