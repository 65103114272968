import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CertificationDto } from "@apiModels/certificationDto";
import { CertificationJsonModel } from "@apiModels/certificationJsonModel";
import { CertificationModel } from "@apiModels/certificationModel";
import { CertificationQuestionModel } from "@apiModels/certificationQuestionModel";
import { ApiRoutes } from "@shared/classes/ApiRoutes";
import { SelectItem } from "primeng/api";
import { catchError } from "rxjs";

@Injectable({ providedIn: "root" })
export class CertificationService {
  constructor(private http: HttpClient) {}

  languageOptions: SelectItem[] = [
    { label: "Dansk", value: "danish" },
    { label: "Română", value: "romanian" },
    { label: "Polski", value: "polish" },
    { label: "हिन्दी (hindi)", value: "hindi" },
    { label: "Українська (ukrainian)", value: "ukrainian" },
    { label: "English", value: "english" },
    { label: "Nederlands", value: "dutch" },
    { label: "Deutsch", value: "german" },
    { label: "Svenska", value: "swedish" },
    { label: "Magyar", value: "hungarian" },
    { label: "Lietuvių", value: "lithuanian" },
    { label: "Русский (Russian)", value: "russian" },
    { label: "العربية (Syrian)", value: "arabic" }
  ];

  getFlagIcon(language: string): string {
    switch (language) {
      case "danish":
        return "dk";
      case "romanian":
        return "ro";
      case "polish":
        return "pl";
      case "hindi":
        return "in";
      case "ukrainian":
        return "ua";
      case "english":
        return "gb";
      case "dutch":
        return "nl";
      case "german":
        return "de";
      case "swedish":
        return "se";
      case "hungarian":
        return "hu";
      case "lithuanian":
        return "lt";
      case "russian":
        return "ru";
      case "arabic":
        return "sy";
      default:
        return "";
    }
  }

  languageLabels = {
    danish: {
      disclamer: "Ved at fuldføre denne test giver du os tilladelse til at gemme dine oplysninger og denne test i op til 5 år.",
      guideLabel: "Vælg de svar, du tror er korrekte! Tryk på 'Send' -knappen nederst, når du har besvaret alle 10 spørgsmål!",
      submitButtonLabel: "Send",
      dialogChrErrorHeaderLabel: "CHR-nummer er forkert!",
      dialogChrErrorBodyLabel: "Det indtastede CHR-nummer er forkert, prøv venligst igen!",
      dialogPassedHeaderLabel: "Tak for din indsendelse",
      dialogPassedBodyLabel: "Din indsendelse var helt korrekt, og du vil snart modtage dit certifikat via e-mail. Du kan nu lukke vinduet.",
      dialogErrorHeaderLabel: "Din indsendelse er ikke helt korrekt",
      dialogErrorBodyLabel: "Bemærk, at alle spørgsmål skal besvares, og nogle har flere korrekte svar!<br /><br />Prøv venligst igen!",
      isRequiredLabel: "Påkrævet",
      languageLabel: "Vælg sprog",
      chrNrLabel: "CHR-nummer",
      firstAndLastnameLabel: "For og efternavn",
      birthdayLabel: "Fødselsdag (dd/mm/yyyy)",
      emailLabel: "E-mail",
      phoneLabel: "Telefon",
      min5lettersLabel: "Min. 5 bogstaver",
      wrongFormatLabel: "Forkert format",
      min8digitsLabel: "Minimum 8 cifre",
      fourToSixDigitsLabel: "4-6 cifre"
    },
    romanian: {
      disclamer: "Completând acest test, ne acordați permisiunea de a stoca informațiile dumneavoastră și acest test pentru până la 5 ani.",
      guideLabel: "Selectați răspunsurile pe care credeți că sunt corecte! Apăsați butonul „Trimiteți” în partea de jos când ați răspuns la toate cele 10 întrebări!",
      submitButtonLabel: "Trimiteți",
      dialogChrErrorHeaderLabel: "Numărul CHR este incorect!",
      dialogChrErrorBodyLabel: "Numărul CHR introdus este incorect, vă rugăm să încercați din nou!",
      dialogPassedHeaderLabel: "Vă mulțumim pentru trimiterea dumneavoastră",
      dialogPassedBodyLabel: "Trimiterea dumneavoastră a fost complet corectă și veți primi certificatul prin e-mail în curând. Puteți închide acum fereastra.",
      dialogErrorHeaderLabel: "Trimiterea dumneavoastră nu este complet corectă",
      dialogErrorBodyLabel: "Rețineți că toate întrebările trebuie să fie răspunse și unele au mai multe răspunsuri corecte!<br /><br />Vă rugăm să încercați din nou!",
      isRequiredLabel: "Obligatoriu",
      languageLabel: "Selectați limba",
      chrNrLabel: "Număr CHR",
      firstAndLastnameLabel: "Nume și prenume",
      birthdayLabel: "Data nașterii (dd/lună/an)",
      emailLabel: "Email",
      phoneLabel: "Telefon",
      min5lettersLabel: "Minim 5 litere",
      wrongFormatLabel: "Format greșit",
      min8digitsLabel: "Minim 8 cifre",
      fourToSixDigitsLabel: "4-6 cifre"
    },
    polish: {
      disclamer: "Przechodząc ten test, udzielasz nam zgody na przechowywanie Twoich informacji oraz tego testu przez okres do 5 lat.",
      guideLabel: "Wybierz odpowiedzi, które uważasz za poprawne! Naciśnij przycisk „Wyślij” na dole, gdy odpowiesz na wszystkie 10 pytań!",
      submitButtonLabel: "Wyślij",
      dialogChrErrorHeaderLabel: "Numer CHR jest niepoprawny!",
      dialogChrErrorBodyLabel: "Wprowadzony numer CHR jest nieprawidłowy, spróbuj ponownie!",
      dialogPassedHeaderLabel: "Dziękujemy za przesłanie",
      dialogPassedBodyLabel: "Twoje zgłoszenie było całkowicie poprawne i wkrótce otrzymasz swój certyfikat drogą mailową. Możesz teraz zamknąć okno.",
      dialogErrorHeaderLabel: "Twoje zgłoszenie nie jest w pełni poprawne",
      dialogErrorBodyLabel: "Zauważ, że wszystkie pytania muszą być udzielone, a niektóre z nich mają kilka poprawnych odpowiedzi!<br /><br />Proszę spróbuj ponownie!",
      isRequiredLabel: "Wymagane",
      languageLabel: "Wybierz język",
      chrNrLabel: "Numer CHR",
      firstAndLastnameLabel: "Imię i nazwisko",
      birthdayLabel: "Data urodzenia (dd/mm/rrrr)",
      emailLabel: "Email",
      phoneLabel: "Telefon",
      min5lettersLabel: "Minimum 5 liter",
      wrongFormatLabel: "Zły format",
      min8digitsLabel: "Minimum 8 cyfr",
      fourToSixDigitsLabel: "Od 4 do 6 cyfr"
    },
    hindi: {
      disclamer: "इस परीक्षा को पूरा करके, आप हमें अपनी जानकारी और इस परीक्षा को 5 साल तक संग्रहीत करने की अनुमति देते हैं।",
      guideLabel: "वह उत्तर (उत्तरों) का चयन करें जिन्हें आप सही मानते हैं! जब आप 10 प्रश्नों का उत्तर दें, तो नीचे 'सबमिट' बटन दबाएँ!",
      submitButtonLabel: "सबमिट करें",
      dialogChrErrorHeaderLabel: "CHR नंबर गलत है!",
      dialogChrErrorBodyLabel: "दर्ज किया गया CHR नंबर गलत है, कृपया पुन: प्रयास करें!",
      dialogPassedHeaderLabel: "आपके सबमिशन के लिए धन्यवाद",
      dialogPassedBodyLabel: "आपका सबमिशन पूरी तरह से सही था और आप शीघ्र ही अपना प्रमाणपत्र ईमेल के माध्यम से प्राप्त करेंगे। आप अब खिड़की को बंद कर सकते हैं।",
      dialogErrorHeaderLabel: "आपका सबमिशन पूरी तरह से सही नहीं है",
      dialogErrorBodyLabel: "कृपया ध्यान दें कि सभी प्रश्नों का उत्तर दिया जाना चाहिए और कुछ प्रश्नों के कई सही उत्तर हो सकते हैं!<br /><br />कृपया पुन: प्रयास करें!",
      isRequiredLabel: "अनिवार्य",
      languageLabel: "भाषा चुनें",
      chrNrLabel: "CHR नंबर",
      firstAndLastnameLabel: "पहला और अंतिम नाम",
      birthdayLabel: "जन्मदिन (दिन/महीना/वर्ष)",
      emailLabel: "ईमेल",
      phoneLabel: "फ़ोन",
      min5lettersLabel: "कम से कम 5 अक्षर",
      wrongFormatLabel: "गलत प्रारूप",
      min8digitsLabel: "न्यूनतम 8 अंक",
      fourToSixDigitsLabel: "4-6 अंक"
    },
    ukrainian: {
      disclamer: "Завершуючи цей тест, ви надаєте нам дозвіл на зберігання вашої інформації та цього тесту протягом до 5 років.",
      guideLabel: "Виберіть відповідь(і), які ви вважаєте правильними! Натисніть кнопку 'Відправити' унизу, коли ви відповіли на всі 10 питань!",
      submitButtonLabel: "Відправити",
      dialogChrErrorHeaderLabel: "Невірний номер CHR!",
      dialogChrErrorBodyLabel: "Введений номер CHR невірний, спробуйте ще раз!",
      dialogPassedHeaderLabel: "Дякуємо за вашу участь",
      dialogPassedBodyLabel: "Ваша відповідь була повністю правильною, і ви незабаром отримаєте свій сертифікат по електронній пошті. Тепер ви можете закрити вікно.",
      dialogErrorHeaderLabel: "Ваша відповідь не є повністю правильною",
      dialogErrorBodyLabel: "Зверніть увагу, що на всі питання потрібно дати відповідь, і на деякі можуть бути декілька правильних відповідей!<br /><br />Спробуйте ще раз!",
      isRequiredLabel: "Обов'язково",
      languageLabel: "Виберіть мову",
      chrNrLabel: "Номер CHR",
      firstAndLastnameLabel: "Ім'я та прізвище",
      birthdayLabel: "Дата народження (дд/мм/рррр)",
      emailLabel: "Електронна пошта",
      phoneLabel: "Телефон",
      min5lettersLabel: "Мінімум 5 літер",
      wrongFormatLabel: "Неправильний формат",
      min8digitsLabel: "Мінімум 8 цифр",
      fourToSixDigitsLabel: "Від 4 до 6 цифр"
    },
    english: {
      disclamer: "By completing this test, you give us permission to store your information and this test for up to 5 years.",
      guideLabel: "Select the answer(s) you believe to be correct! Press the 'Submit' button at the bottom when you have answered all 10 questions!",
      submitButtonLabel: "Submit",
      dialogChrErrorHeaderLabel: "CHR number is incorrect!",
      dialogChrErrorBodyLabel: "The entered CHR number is incorrect, please try again!",
      dialogPassedHeaderLabel: "Thank you for your submission",
      dialogPassedBodyLabel: "Your submission was completely correct and you will shortly receive your certificate via email. You may now close the window.",
      dialogErrorHeaderLabel: "Your submission is not entirely correct",
      dialogErrorBodyLabel: "Please note that all questions must be answered and some have multiple correct answers!<br /><br />Please try again!",
      isRequiredLabel: "Required",
      languageLabel: "Select language",
      chrNrLabel: "CHR Number",
      firstAndLastnameLabel: "First and last name",
      birthdayLabel: "Birthday (dd/mm/yyyy)",
      emailLabel: "Email",
      phoneLabel: "Phone",
      min5lettersLabel: "Min 5 letters",
      wrongFormatLabel: "Wrong format",
      min8digitsLabel: "Minimum 8 digits",
      fourToSixDigitsLabel: "4-6 digits"
    },
    dutch: {
      disclamer: "Door deze test in te vullen, geeft u ons toestemming om uw informatie en deze test tot 5 jaar op te slaan.",
      guideLabel: "Selecteer de antwoord(en) die u juist acht! Druk op de 'Verzenden' knop onderaan wanneer u alle 10 vragen heeft beantwoord!",
      submitButtonLabel: "Verzenden",
      dialogChrErrorHeaderLabel: "CHR-nummer is onjuist!",
      dialogChrErrorBodyLabel: "Het ingevoerde CHR-nummer is onjuist, probeer het opnieuw!",
      dialogPassedHeaderLabel: "Bedankt voor uw inzending",
      dialogPassedBodyLabel: "Uw inzending was helemaal correct en u ontvangt binnenkort uw certificaat via e-mail. U kunt het venster nu sluiten.",
      dialogErrorHeaderLabel: "Uw inzending is niet helemaal correct",
      dialogErrorBodyLabel: "Let op dat alle vragen beantwoord moeten worden en sommige meerdere juiste antwoorden hebben!<br /><br />Probeer het opnieuw!",
      isRequiredLabel: "Verplicht",
      languageLabel: "Selecteer taal",
      chrNrLabel: "CHR-nummer",
      firstAndLastnameLabel: "Voornaam en achternaam",
      birthdayLabel: "Geboortedatum (dd/mm/jjjj)",
      emailLabel: "E-mail",
      phoneLabel: "Telefoon",
      min5lettersLabel: "Minimaal 5 letters",
      wrongFormatLabel: "Verkeerd formaat",
      min8digitsLabel: "Minimaal 8 cijfers",
      fourToSixDigitsLabel: "4-6 cijfers"
    },
    german: {
      disclamer: "Durch die Absolvierung dieses Tests geben Sie uns die Erlaubnis, Ihre Informationen und diesen Test bis zu 5 Jahre lang zu speichern.",
      guideLabel: "Wählen Sie die Antwort(en), von denen Sie glauben, dass sie richtig sind! Drücken Sie die Schaltfläche 'Senden' unten, wenn Sie alle 10 Fragen beantwortet haben!",
      submitButtonLabel: "Senden",
      dialogChrErrorHeaderLabel: "CHR-Nummer ist inkorrekt!",
      dialogChrErrorBodyLabel: "Die eingegebene CHR-Nummer ist inkorrekt. Bitte versuchen Sie es erneut!",
      dialogPassedHeaderLabel: "Vielen Dank für Ihre Einreichung",
      dialogPassedBodyLabel: "Ihre Einreichung war vollständig korrekt, und Sie erhalten in Kürze Ihr Zertifikat per E-Mail. Sie können das Fenster jetzt schließen.",
      dialogErrorHeaderLabel: "Ihre Einreichung ist nicht vollständig korrekt",
      dialogErrorBodyLabel: "Bitte beachten Sie, dass alle Fragen beantwortet werden müssen und einige mehrere richtige Antworten haben können!<br /><br />Bitte versuchen Sie es erneut!",
      isRequiredLabel: "Erforderlich",
      languageLabel: "Sprache wählen",
      chrNrLabel: "CHR-Nummer",
      firstAndLastnameLabel: "Vor- und Nachname",
      birthdayLabel: "Geburtstag (dd/mm/yyyy)",
      emailLabel: "E-Mail",
      phoneLabel: "Telefon",
      min5lettersLabel: "Mindestens 5 Buchstaben",
      wrongFormatLabel: "Falsches Format",
      min8digitsLabel: "Mindestens 8 Ziffern",
      fourToSixDigitsLabel: "4-6 Ziffern"
    },
    swedish: {
      disclamer: "Genom att slutföra detta test ger du oss tillstånd att lagra din information och detta test i upp till 5 år.",
      guideLabel: "Välj de svar du tror är korrekta! Tryck på 'Skicka' -knappen längst ner när du har svarat på alla 10 frågor!",
      submitButtonLabel: "Skicka",
      dialogChrErrorHeaderLabel: "CHR-numret är felaktigt!",
      dialogChrErrorBodyLabel: "Det angivna CHR-numret är felaktigt, försök igen!",
      dialogPassedHeaderLabel: "Tack för din insats",
      dialogPassedBodyLabel: "Din insats var helt korrekt och du kommer snart att få ditt certifikat via e-post. Du kan nu stänga fönstret.",
      dialogErrorHeaderLabel: "Din insats är inte helt korrekt",
      dialogErrorBodyLabel: "Observera att alla frågor måste besvaras och vissa har flera korrekta svar!<br /><br />Försök igen!",
      isRequiredLabel: "Obligatoriskt",
      languageLabel: "Välj språk",
      chrNrLabel: "CHR-nummer",
      firstAndLastnameLabel: "För- och efternamn",
      birthdayLabel: "Födelsedag (dd/mm/åååå)",
      emailLabel: "E-post",
      phoneLabel: "Telefon",
      min5lettersLabel: "Minst 5 bokstäver",
      wrongFormatLabel: "Fel format",
      min8digitsLabel: "Minst 8 siffror",
      fourToSixDigitsLabel: "4-6 siffror"
    },
    hungarian: {
      disclamer: "A teszt kitöltésével hozzájárulsz ahhoz, hogy az adataidat és ezt a tesztet legfeljebb 5 évig tároljuk.",
      guideLabel: "Válaszd ki azokat a válaszokat, amelyeket helyesnek gondolsz! Nyomd meg az 'Elküld' gombot, amikor minden 10 kérdésre válaszoltál!",
      submitButtonLabel: "Elküld",
      dialogChrErrorHeaderLabel: "CHR-szám helytelen!",
      dialogChrErrorBodyLabel: "A megadott CHR-szám helytelen, kérlek, próbáld újra!",
      dialogPassedHeaderLabel: "Köszönjük a beküldést",
      dialogPassedBodyLabel: "A beküldött adatok teljesen helyesek voltak, hamarosan megkapod a tanúsítványodat emailben. Most már bezárhatod az ablakot.",
      dialogErrorHeaderLabel: "A beküldésed nem teljesen helyes",
      dialogErrorBodyLabel: "Kérlek, vedd figyelembe, hogy minden kérdésre válaszolnod kell, és néhány kérdésnek több helyes válasza is lehet!<br /><br />Próbáld újra!",
      isRequiredLabel: "Kötelező",
      languageLabel: "Nyelv kiválasztása",
      chrNrLabel: "CHR-szám",
      firstAndLastnameLabel: "Keresztnév és vezetéknév",
      birthdayLabel: "Születésnap (dd/hh/éééé)",
      emailLabel: "Email",
      phoneLabel: "Telefonszám",
      min5lettersLabel: "Minimum 5 betű",
      wrongFormatLabel: "Helytelen formátum",
      min8digitsLabel: "Minimum 8 számjegy",
      fourToSixDigitsLabel: "4-6 számjegy"
    },
    lithuanian: {
      disclamer: "Atlikdami šį testą, suteikiate mums leidimą saugoti jūsų informaciją ir šį testą iki 5 metų.",
      guideLabel: "Pasirinkite atsakymą (-us), kurį (-ius) laikote teisingu (-ais)! Paspauskite mygtuką „Pateikti“ apačioje, kai atsakėte į visus 10 klausimų!",
      submitButtonLabel: "Pateikti",
      dialogChrErrorHeaderLabel: "CHR numeris yra neteisingas!",
      dialogChrErrorBodyLabel: "Įvestas CHR numeris yra neteisingas, prašome bandyti dar kartą!",
      dialogPassedHeaderLabel: "Ačiū už jūsų pateikimą",
      dialogPassedBodyLabel: "Jūsų pateikimas buvo visiškai teisingas ir netrukus gausite sertifikatą el. paštu. Galite uždaryti langą.",
      dialogErrorHeaderLabel: "Jūsų pateikimas nėra visiškai teisingas",
      dialogErrorBodyLabel: "Prašome atkreipti dėmesį, kad į visus klausimus būtina atsakyti, o kai kurie turi kelis teisingus atsakymus!<br /><br />Bandykite dar kartą!",
      isRequiredLabel: "Privaloma",
      languageLabel: "Pasirinkite kalbą",
      chrNrLabel: "CHR Numeris",
      firstAndLastnameLabel: "Pirmas ir paskutinis vardas",
      birthdayLabel: "Gimimo data (dd/mm/yyyy)",
      emailLabel: "El. paštas",
      phoneLabel: "Telefono numeris",
      min5lettersLabel: "Min. 5 raidės",
      wrongFormatLabel: "Neteisingas formatas",
      min8digitsLabel: "Mažiausiai 8 skaitmenys",
      fourToSixDigitsLabel: "4-6 skaitmenys"
    },
    russian: {
      disclamer: "Заполняя этот тест, вы даете нам разрешение на хранение ваших данных и этого теста в течение до 5 лет.",
      guideLabel: "Выберите ответ(ы), которые, по вашему мнению, верны! Нажмите кнопку 'Отправить' внизу, когда ответите на все 10 вопросов!",
      submitButtonLabel: "Отправить",
      dialogChrErrorHeaderLabel: "Неправильный номер CHR!",
      dialogChrErrorBodyLabel: "Введен неправильный номер CHR, пожалуйста, попробуйте снова!",
      dialogPassedHeaderLabel: "Спасибо за вашу заявку",
      dialogPassedBodyLabel: "Ваша заявка была полностью правильной, и вы в скором времени получите ваш сертификат по электронной почте. Теперь вы можете закрыть окно.",
      dialogErrorHeaderLabel: "Ваша заявка не полностью правильна",
      dialogErrorBodyLabel: "Обратите внимание, что на все вопросы должны быть даны ответы, и на некоторые из них может быть несколько правильных ответов!<br /><br />Пожалуйста, попробуйте снова!",
      isRequiredLabel: "Обязательно",
      languageLabel: "Выберите язык",
      chrNrLabel: "Номер CHR",
      firstAndLastnameLabel: "Имя и фамилия",
      birthdayLabel: "Дата рождения (дд/мм/гггг)",
      emailLabel: "Эл. почта",
      phoneLabel: "Телефон",
      min5lettersLabel: "Минимум 5 букв",
      wrongFormatLabel: "Неправильный формат",
      min8digitsLabel: "Минимум 8 цифр",
      fourToSixDigitsLabel: "4-6 цифр"
    },
    arabic: {
      disclamer: "باستكمال هذا الاختبار، تعطينا الإذن بتخزين معلوماتك وهذا الاختبار لمدة تصل إلى 5 سنوات.",
      guideLabel: "اختر الإجابة (الإجابات) التي تعتقد أنها صحيحة! اضغط على زر 'تقديم' في الأسفل عند الإجابة على جميع الأسئلة العشر!",
      submitButtonLabel: "تقديم",
      dialogChrErrorHeaderLabel: "رقم CHR غير صحيح!",
      dialogChrErrorBodyLabel: "الرقم CHR المدخل غير صحيح، يرجى المحاولة مرة أخرى!",
      dialogPassedHeaderLabel: "شكراً لتقديمك",
      dialogPassedBodyLabel: "كان تقديمك صحيحًا تمامًا وستتلقى شهادتك قريبًا عبر البريد الإلكتروني. يمكنك الآن إغلاق النافذة.",
      dialogErrorHeaderLabel: "تقديمك غير صحيح بالكامل",
      dialogErrorBodyLabel: "يرجى ملاحظة أنه يجب الإجابة على جميع الأسئلة وبعضها يحتوي على إجابات صحيحة متعددة! <br /> <br /> يرجى المحاولة مرة أخرى!",
      isRequiredLabel: "مطلوب",
      languageLabel: "اختر اللغة",
      chrNrLabel: "رقم CHR",
      firstAndLastnameLabel: "الاسم الأول والأخير",
      birthdayLabel: "تاريخ الميلاد (يوم/شهر/سنة)",
      emailLabel: "البريد الإلكتروني",
      phoneLabel: "الهاتف",
      min5lettersLabel: "الحد الأدنى 5 أحرف",
      wrongFormatLabel: "تنسيق خاطئ",
      min8digitsLabel: "الحد الأدنى 8 أرقام",
      fourToSixDigitsLabel: "4-6 أرقام"
    }
  };

  public getCertifications() {
    return this.http.get<CertificationDto[]>(ApiRoutes.certificationRoutes.get.getCertifications, {
      params: {}
    });
  }

  // public getCertification(userId: number): Observable<CertificationModel> {
  //   const params: { [key: string]: string } = {};
  //   params.id = userId.toString();

  //   return this.http.get<CertificationModel>(ApiRoutes.certificationRoutes.get.getCertification, {
  //     params: params
  //   });
  // }

  // public getCertification(model: CertificationModel): Observable<CertificationModel> {
  //   return this.http.post<CertificationModel>(ApiRoutes.certificationRoutes.post.getCertification, model).pipe(
  //     catchError(err => {
  //       // Handle errors here
  //       throw err;
  //     })
  //   );
  // }

  // public getCertification(model: CertificationModel): Observable<CertificationModel> {
  //   return this.http.get<CertificationModel>(ApiRoutes.certificationRoutes.get.getCertification, {
  //     params: { model: model }
  //   });
  // }

  public updateCertification(model: CertificationModel) {
    return this.http.post<number>(ApiRoutes.certificationRoutes.post.updateCertification, model).pipe(
      catchError(err => {
        throw err;
      })
    );
  }

  public createOrGetCertification(model: CertificationModel) {
    return this.http.post<CertificationModel>(ApiRoutes.certificationRoutes.post.createOrGetCertification, model).pipe(
      catchError(err => {
        throw err;
      })
    );
  }

  public getCertificationQuestions(language: string) {
    return this.http.get<CertificationQuestionModel[]>(ApiRoutes.certificationRoutes.get.getCertificationQuestions, {
      params: { language }
    });
  }

  public getCertificationJsonModel(language: string) {
    return this.http.get<CertificationJsonModel>(ApiRoutes.certificationRoutes.get.getCertificationJsonModel, {
      params: { language }
    });
  }

  public insertOrUpdateCertification(model: CertificationModel) {
    return this.http.post<number>(ApiRoutes.certificationRoutes.post.insertOrUpdateCertification, model).pipe(
      catchError(err => {
        throw err;
      })
    );
  }

  public sendCertificate(model: CertificationModel) {
    return this.http.post<number>(ApiRoutes.certificationRoutes.post.sendCertificate, model).pipe(
      catchError(err => {
        throw err;
      })
    );
  }
}
