<div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
  <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 80%">
    <div class="col-12 mt-5 xl:mt-0 text-center">
      <img src="https://neeobovis.dk/wp-content/uploads/2023/04/Logo_boovis_groen.png" alt="Neeo Bovis logo" class="mb-5" style="height: 121px" />
    </div>
    <div class="col-12 xl:col-6" style="border-radius: 56px; padding: 0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)">
      <div class="h-full w-full m-0 py-7 px-4" style="border-radius: 53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0))">
        <div class="text-center mb-5">
          <div class="text-900 text-3xl font-medium mb-3">Velkommen</div>
          <span class="text-600 font-medium">Log ind for at forsætte</span>
        </div>

        <div class="w-full md:w-10 mx-auto">
          <form [formGroup]="loginForm" id="login-form" *ngIf="!hideLoginForm">
            <div>
              <label for="email1" class="block text-900 font-medium mb-2">Email</label>
              <input
                id="email1"
                type="text"
                pInputText
                class="w-full mb-3"
                type="email"
                [readonly]="emailIsReadonly"
                [placeholder]="'shared.EnterEmail' | translate"
                (keyup.enter)="login()"
                formControlName="loginEmail"
                autocomplete="email"
                (change)="email.setValue($any($event.target).value)"
              />

              <p class="field-error" *ngIf="email.errors?.email && email.touched" translate>errorMessages.emailInvalid</p>
              <p class="field-error" *ngIf="email.errors?.required && email.touched" translate>errorMessages.MustBeFilled</p>

              <label for="password1" class="block text-900 font-medium mb-2">Adgangskode</label>
              <input id="password1" type="password" pInputText class="w-full mb-3" formControlName="password" />

              <p-message *ngIf="failedLoginMessage" severity="error" [text]="failedLoginMessage"></p-message>
              <div class="flex align-items-center justify-content-between mb-6">
                <div class="flex align-items-center">
                  <p-checkbox id="rememberme1" [binary]="true" styleClass="mr-2"></p-checkbox>
                  <label for="rememberme1">Husk mig</label>
                </div>
                <a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer" [routerLink]="['/forgot-password']">Glemt adgangskode?</a>
              </div>

              <button pButton pRipple icon="pi pi-user" class="w-full" [disabled]="loginForm.invalid || isLoading" [label]="'shared.Login' | translate" (click)="login()"></button><br /><br />
              <b>AD login: (Ansatte)</b>
              <button pButton pRipple icon="pi pi-user" class="w-full" [disabled]="isLoading" [label]="'shared.LoginAD' | translate" (click)="loginAD()"></button>
              <!-- {{ loginForm.invalid }} {{ email.errors }} {{ password.invalid }} -->
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
