<p-accordion [activeIndex]="0">
  <p-accordionTab header="Filtrer">
    <div class="flex-container">
      <div class="p-field flex-item">
        <label for="userFilter">Tildelt : </label>
        <p-dropdown id="userFilter" class="filter-dropdown ml-3" optionLabel="label" dataKey="value" [options]="userItemsWithAllAndNone" [(ngModel)]="selectedUserItem"></p-dropdown>
      </div>

      <div class="p-field flex-item">
        <label for="statusFilter">Status : </label>
        <p-dropdown id="statusFilter" class="filter-dropdown ml-3" optionLabel="label" dataKey="value" [options]="statusFilterItems" [(ngModel)]="selectedStatusFilterItem"></p-dropdown>
      </div>

      <div class="p-field flex-item">
        <label for="kategoriFilter">Kategori : </label>
        <p-multiSelect id="kategoriFilter" class="filter-multiselect ml-3" optionLabel="label" dataKey="value" [options]="categoryItems" [(ngModel)]="selectedCategoryItems"></p-multiSelect>
      </div>

      <div class="p-field flex-item">
        <button pButton type="button" (click)="handleFilterChange()">Filtrer</button> <button pButton class="ml-2" type="button" (click)="resetFilter(true)">Nulstill filter</button>
      </div>
    </div>
  </p-accordionTab>
</p-accordion>

<div class="grid">
  <div class="col-12">
    <div class="card">
      <p-toast></p-toast>
      <p-table
        #table
        [value]="itemList$ | async"
        [columns]="columns$ | async"
        [globalFilterFields]="globalFilterFields$ | async"
        [loading]="loading"
        selectionMode="single"
        [(selection)]="selectedItem"
        dataKey="id"
        (onRowSelect)="onRowSelect($event)"
        csvSeparator=";"
        [exportFilename]="'export'"
        [paginator]="true"
        [rows]="25"
        [showCurrentPageReport]="true"
        responsiveLayout="scroll"
        currentPageReportTemplate="Viser {first} til {last} af {totalRecords} poster"
        [rowsPerPageOptions]="[25, 50, 100, 500]"
      >
        <ng-template pTemplate="caption">
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 class="m-0">Opgaver</h5>

            <button [disabled]="!appointments" pButton pRipple label="vis kalender (TEST)" icon="pi pi-calendar" class="p-button-success mr-2" (click)="calendarDialogVisible = true"></button>

            <button pButton pRipple label="Opret opgave" icon="pi pi-plus" class="p-button-success mr-2" (click)="addNewActivity()"></button>

            <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help" (click)="exportToExcel()"></button>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="table.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th>Prospect</th>
            <th>Kort</th>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ col.header }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
            <td>
              <div *ngIf="rowData.prospectId" class="flex">
                <div>
                  <button pButton pRipple icon="pi pi-external-link" class="p-button-rounded p-button-success mr-2" (click)="openFarmDialog(rowData)"></button>
                </div>
              </div>
            </td>
            <td>
              <div *ngIf="rowData.hubspotCompanyId" class="flex">
                <div>
                  <button pButton pRipple icon="pi pi-map-marker" class="p-button-rounded p-button-success mr-2" (click)="showOnMap(rowData)"></button>
                </div>
              </div>
            </td>
            <td *ngFor="let col of columns" [innerHtml]="rowData[col.field]" [pTooltip]="col.toolTip ? rowData[col.toolTip] : ''" tooltipPosition="top"></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-dialog
  *ngIf="dialogVisible"
  [header]="'Rediger opgave'"
  [(visible)]="dialogVisible"
  [modal]="true"
  (onHide)="handleCloseDialog(null)"
  [style]="{ width: '90rem', minHeight: '90vh' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [maximizable]="true"
>
  <app-activity-detail *ngIf="dialogVisible" [activityId]="selectedItem.id" [activity]="selectedItem" (closeDialog)="handleCloseDialog()" (onClose)="handleCloseDialog()"></app-activity-detail>
</p-dialog>

<p-dialog
  *ngIf="farmDialogVisible"
  [header]="'Rediger farm' | translate"
  [(visible)]="farmDialogVisible"
  [modal]="true"
  (onHide)="farmDialogVisible = false"
  [style]="{ width: '95vw', minHeight: '90vh' }"
  [maximizable]="true"
  (farmDialogVisibleChange)="farmDialogVisible = false"
>
  <app-prospect-detail [hubspotCompanyId]="selectedFarmId" (farmDialogVisibleChange)="farmDialogVisible = false"></app-prospect-detail>
</p-dialog>

<p-dialog *ngIf="calendarDialogVisible" [header]="'Kalender' | translate" [(visible)]="calendarDialogVisible" [style]="{ width: '90vw', minHeight: '70vh' }" [maximizable]="true">
  <app-scheduler [appointments]="appointments"></app-scheduler>
</p-dialog>
