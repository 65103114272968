import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NoteDto } from "@apiModels/TreatmentParserNote";
import { TreatmentSeriesDto } from "@apiModels/treatmentSeriesDto";
import { TreatmentSeriesFilterDto } from "@apiModels/treatmentSeriesFilterDto";
import { ApiRoutes } from "@shared/classes/ApiRoutes";
import { map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class TreatmentService {
  constructor(private http: HttpClient) {}

  public getTreatments(filter: TreatmentSeriesFilterDto): Observable<TreatmentSeriesDto[]> {
    return this.http.post<TreatmentSeriesDto[]>(ApiRoutes.treatmentRoutes.get.getTreatments, filter).pipe(map(items => items));
  }

  public getTreatmentSeriesFromExcel(note: NoteDto): Observable<TreatmentSeriesDto[]> {
    return this.http.post<TreatmentSeriesDto[]>(ApiRoutes.treatmentRoutes.get.getTreatmentSeriesFromExcel, note).pipe(map(items => items));
  }

  // public getActivityFromId(id: number): Observable<ActivityDto> {
  //   const params: { [key: string]: string } = { id: id.toString() };
  //   return this.http.get<ActivityDto>(ApiRoutes.activityRoutes.get.getActivityFromId, { params: params });
  // }

  // public updateActivity(dto: ActivityDto): Observable<any> {
  //   return this.http.post<any>(ApiRoutes.activityRoutes.update.updateActivity, dto);
  // }

  // public getProspectActivities(prospectId: number): Observable<ActivityDto[]> {
  //   const params: { [key: string]: string } = { prospectId: prospectId.toString() };

  //   return this.http
  //     .get<ActivityDto[]>(ApiRoutes.activityRoutes.get.getProspectActivities, {
  //       params: params
  //     })
  //     .pipe(
  //       map(items => {
  //         return items;
  //       })
  //     );
  // }
}
