import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { ProspectNoteDto } from "@apiModels/prospectNoteDto";
import { HubspotService } from "@globals/services/hubspot.service";
import moment from "moment";
import { SelectItem } from "primeng/api";

@Component({
  selector: "app-note-edit-dialog",
  templateUrl: "./note-edit-dialog.component.html",
  styleUrls: ["./note-edit-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoteEditDialogComponent implements OnInit, OnChanges {
  @Input() visible = false;
  @Input() note: ProspectNoteDto | null = null;
  @Input() hubspotCompanyId: number; // Add this input
  @Output() onSave = new EventEmitter<ProspectNoteDto>();
  @Output() onClose = new EventEmitter<void>();

  noteText = "";
  selectedNoteType: SelectItem | null = null;
  selectedDate: Date;
  selectedTime: string; // New property for selected time

  noteTypes: SelectItem[] = [
    { label: "Notat", value: "NOTE" },
    { label: "Opkald", value: "CALL" },
    { label: "Opgave", value: "TASK" },
    { label: "Møde", value: "MEETING" },
    { label: "Email", value: "EMAIL" }
  ];

  timeIntervals: SelectItem[] = []; // Array for time intervals

  constructor(
    private hubspotService: HubspotService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initializeTimeIntervals();
    if (this.note) {
      this.noteText = this.note.body || "";
      this.selectedNoteType = this.noteTypes.find(type => type.value === this.note.type) || null;
      this.initializeNoteFields();
    }
  }

  ngOnChanges(): void {
    if (this.note) {
      this.noteText = this.note.body || "";
      this.selectedNoteType = this.noteTypes.find(type => type.value === this.note.type) || null;
      this.initializeNoteFields();
    }
  }

  initializeTimeIntervals(): void {
    for (let i = 0; i < 24 * 2; i++) {
      const hours = Math.floor(i / 2)
        .toString()
        .padStart(2, "0");
      const minutes = i % 2 === 0 ? "00" : "30";
      this.timeIntervals.push({ label: `${hours}:${minutes}`, value: `${hours}:${minutes}` });
    }
  }

  initializeNoteFields(): void {
    if (this.note?.dateFollowUpUtc) {
      const localFollowupDate = new Date(moment(this.note.dateFollowUpUtc).toLocaleString());
      this.selectedDate = localFollowupDate;
      const hours = localFollowupDate.getHours().toString().padStart(2, "0");
      const minutes = localFollowupDate.getMinutes().toString().padStart(2, "0");
      this.selectedTime = `${hours}:${minutes}`;
    } else {
      this.selectedDate = null;
      this.selectedTime = "08:00";
    }

    this.selectedNoteType = this.noteTypes.find(f => f.value === this.note.type);
  }

  saveNote(): void {
    let followupDateString = null;

    if (this.selectedDate) {
      const [hours, minutes] = this.selectedTime.split(":").map(num => parseInt(num, 10));
      this.selectedDate.setHours(hours, minutes, 0, 0);
      followupDateString = this.selectedDate.toISOString();
    }

    const updatedNote: ProspectNoteDto = {
      ...this.note,
      body: this.noteText,
      prospectId: this.hubspotCompanyId,
      type: this.selectedNoteType?.value || "",
      dateFollowUpUtc: followupDateString
    };

    this.hubspotService.saveProspectNote(updatedNote).subscribe({
      next: () => {
        this.visible = false;
        this.cd.detectChanges();
        this.onSave.emit(updatedNote);
        this.onClose.emit();
      },
      error: error => {
        console.error("Error saving note", error);
      }
    });
  }

  closeDialog(): void {
    this.visible = false;
    this.onClose.emit();
  }

  convertHtmlToPlainText(html: string): string {
    const div = document.createElement("div");
    div.innerHTML = html;

    const brRegex = /<br\s*\/?>/gi;
    let text = div.innerHTML.replace(brRegex, "\n");

    const blockElements = ["p", "div", "li"];
    blockElements.forEach(tag => {
      const regex = new RegExp(`<${tag}[^>]*>`, "gi");
      text = text.replace(regex, "\n");
      text = text.replace(new RegExp(`</${tag}>`, "gi"), "\n");
    });

    div.innerHTML = text;
    return div.innerText.replace(/\n\s*\n/g, "\n\n");
  }
}
