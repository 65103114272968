// week-number.ts
export function getWeekNumber(date: Date): number {
  // Clone date to avoid modifying the original date
  const tempDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

  // Set to nearest Thursday: current date + 4 - current day number (adjusted to be in range 0-6)
  tempDate.setUTCDate(tempDate.getUTCDate() + 4 - (tempDate.getUTCDay() || 7));

  // Get first day of the year
  const yearStart = new Date(Date.UTC(tempDate.getUTCFullYear(), 0, 1));

  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil((((tempDate as any) - (yearStart as any)) / 86400000 + 1) / 7);

  return weekNo;
}
