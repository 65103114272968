import { Component, OnInit } from "@angular/core";
import { KanbanItemDto } from "@apiModels/kanbanItemDto";
import { KanbanItemFilterDto } from "@apiModels/kanbanItemFilterDto";
import { ActivityService } from "@globals/services/activity.service";
import { CardSettingsModel, DragEventArgs, SwimlaneSettingsModel } from "@syncfusion/ej2-angular-kanban";
import { L10n } from "@syncfusion/ej2-base";
import { map } from "rxjs";
import { UserInfo, userInfos } from "../activities/shared-data";
import { DataForKanban } from "./KanbanData";

// Function to transform ProjectDto[] to KanbanData[]
const transformToKanbanData = (projects: KanbanItemDto[], userInfos: UserInfo[]): DataForKanban[] => {
  const result: DataForKanban[] = [];

  projects.forEach(task => {
    result.push({
      Id: task.id?.toString() || "",
      Priority: task.priority || "",
      Summary: task.summary || "",
      AssigneeUserId: task.assigneeUserId,
      Assignee: userInfos.find(u => u.id === +task.assigneeUserId)?.fullName || null,
      SortOrder: task.sortOrder || null,
      Status: task.status || "",
      Type: task.type || "",
      ParentId: task.parentId?.toString() || null,
      ParentName: task.parentName || null
    });
  });

  return result;
};
// Load Danish localization for Kanban
L10n.load({
  da: {
    kanban: {
      items: "Elementer",
      min: "Min",
      max: "Maks",
      cardsSelected: "Kort valgt",
      addTitle: "Tilføj nyt kort",
      editTitle: "Rediger kortdetaljer",
      deleteTitle: "Slet kort",
      deleteContent: "Vil du virkelig slette dette kort?",
      save: "Gem",
      delete: "Slet",
      cancel: "Annuller",
      yes: "Ja",
      no: "Nej",
      close: "Luk",
      noCard: "Ingen kort at vise",
      unassigned: "Ikke tildelt"
    }
  }
});

@Component({
  selector: "app-kanban-test",
  templateUrl: "./kanban-test.component.html",
  styleUrls: ["./kanban-test.component.scss"]
})
export class KanbanTestComponent implements OnInit {
  public data: DataForKanban[] = [];

  public userInfos: { [key: string]: UserInfo } = userInfos;

  public userInfosArray = Object.values(this.userInfos);

  public selectedAssigneeId: number | null = null;

  public cardSettings: CardSettingsModel = {
    headerField: "Id"
  };

  public swimlaneSettings: SwimlaneSettingsModel = {
    keyField: "ParentId", // The field used to group by parent ID
    textField: "ParentName", // Field to display the swimlane name
    allowDragAndDrop: true,
    showEmptyRow: true
  };

  public tooltipSettings: any = {
    content: "#tooltipTemplate", // Referencing the tooltip template
    showOn: "Hover", // Tooltip will show on hover
    position: "TopCenter" // Tooltip position
  };

  constructor(private activityService: ActivityService) {}

  ngOnInit() {
    const filter: KanbanItemFilterDto = {};
    this.activityService
      .getAllKanbanItems(filter)
      .pipe(map(projects => transformToKanbanData(projects, Object.values(this.userInfos))))
      .subscribe(kanbanData => {
        this.data = kanbanData;
      });
  }

  // Triggered when dragging stops
  public onDragStop(args: DragEventArgs): void {
    const draggedItems = args.data as DataForKanban[];
    const draggedItem = draggedItems[0];
    const dropIndex = args.dropIndex;

    const parentTasks = this.data.filter(task => task.ParentId === draggedItem.ParentId && task.Status === draggedItem.Status && task.Id !== draggedItem.Id);

    parentTasks.sort((a, b) => a.SortOrder - b.SortOrder);
    parentTasks.splice(dropIndex, 0, draggedItem);

    parentTasks.forEach((task, index) => {
      task.SortOrder = index;
    });

    this.activityService.updateTasks(parentTasks).subscribe(response => {
      // Handle success/failure
    });
  }

  // public onAssigneeChange(event: any, card: DataForKanban) {
  //   const assignee = this.userInfos[event.value];
  //   card.Assignee = assignee.fullName;
  //   card.AssigneeUserId = assignee.id;
  //   this.activityService.updateTasks([card]).subscribe(response => {
  //     // Handle success/failure
  //   });
  // }

  public onAssigneeChange(event: any, card: DataForKanban): void {
    // Find the selected assignee in the userInfos array based on the selected user ID
    //const assignee = this.userInfos.find(user => user.id === event.value);
    const assignee = Object.values(this.userInfos).find(user => user.id === event.value);

    // If an assignee is found, update the card with their details
    if (assignee) {
      card.Assignee = assignee.fullName;
      card.AssigneeUserId = assignee.id;
    }

    // Call the service to update the task on the backend
    this.activityService.updateTasks([card]).subscribe(response => {
      // Handle the success/failure of the task update
      console.log("Task updated successfully", response);
    });
  }
}
