<p-dialog *ngIf="visible" header="Rediger farm" [(visible)]="visible" [modal]="true" [style]="{ 'min-width': '40vw', width: 'auto' }" (onHide)="closeDialog()">
  <div class="p-grid p-fluid">
    <div class="p-col-12">
      <div class="p-field">
        <label for="farmName">Navn</label>
        <input
          id="farmName"
          required
          minlength="3"
          [(ngModel)]="farmName"
          pInputText
          placeholder="Indtast farmnavn"
          #farmNameInput="ngModel"
          [ngClass]="{ 'ng-dirty': farmNameInput.invalid && farmNameInput.touched }"
          required
        />
      </div>
    </div>

    <div class="p-col-12">
      <div class="p-field">
        <label for="farmAddress">Adresse</label>
        <input id="farmAddress" [(ngModel)]="farmAddress" pInputText placeholder="Indtast adresse" />
      </div>
    </div>

    <div class="p-col-12">
      <div class="p-field">
        <label for="farmPhone">Telefon</label>
        <input id="farmPhone" type="number" [(ngModel)]="farmPhone" pInputText placeholder="Indtast telefonnummer" />
      </div>
    </div>

    <div class="p-col-12">
      <div class="p-field">
        <label for="farmEmail">Email</label>
        <input id="farmEmail" type="email" [(ngModel)]="farmEmail" pInputText placeholder="Indtast email" />
      </div>
    </div>

    <div class="p-col-12">
      <div class="p-field">
        <label for="farmInfo">Info</label>
        <textarea id="farmInfo" rows="3" [(ngModel)]="farmInfo" pInputTextarea placeholder="Indtast info"></textarea>
      </div>
    </div>

    <div *ngIf="farm.id > 0" class="p-col-6">
      <div class="p-field">
        <label for="farmChrNr">Chr Nr</label>
        <input id="farmChrNr" type="number" [(ngModel)]="farmChrNr" pInputText placeholder="Indtast Chr Nr" />
      </div>
    </div>

    <div class="p-col-6">
      <div class="p-field">
        <label for="farmBesNr">Besætnings Nr</label>
        <input id="farmBesNr" type="number" [(ngModel)]="farmBesNr" pInputText placeholder="Indtast Besætnings Nr" />
      </div>
    </div>
  </div>

  <p-footer>
    <button pButton label="Gem" icon="pi pi-check" class="p-button-success" (click)="saveFarm()"></button>
    <button pButton label="Annuller" icon="pi pi-times" class="p-button-secondary" (click)="closeDialog()"></button>
  </p-footer>
</p-dialog>
