import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { LocaleStringResource } from "@apiModels/localeStringResource";
import { GlobalStateAndEventsService } from "@core/global-state-and-events.service";
import { BiLanguageId, getLanguageTranslationKeyByLanguageId } from "@enums/BiLanguageAndCountryId";
import { CustomerService } from "@globals/services/customer.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SelectItem } from "primeng/api";
import { finalize } from "rxjs";

@Component({
  selector: "app-locale-string-resources",
  templateUrl: "./locale-string-resources.component.html",
  styleUrls: ["./locale-string-resources.component.scss"]
})
@UntilDestroy()
export class LocaleStringResourcesComponent implements OnInit {
  localeStringResources: LocaleStringResource[] = [];
  filteredLocaleStringResources: LocaleStringResource[] = [];
  languages: SelectItem[] = [];
  filteredLanguages: SelectItem[] = [];
  selectedLanguageId: number;

  loading = true;

  showEditDialog = false;
  mainForm: UntypedFormGroup;

  constructor(private customerService: CustomerService, private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.languages = Object.keys(BiLanguageId)
      .filter(key => !isNaN(Number(BiLanguageId[key])))
      .map(key => ({ label: getLanguageTranslationKeyByLanguageId(BiLanguageId[key]), value: BiLanguageId[key] }));

    console.log(this.languages);

    this.customerService
      .getAllLocalizedResources()
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.loading = false))
      )
      .subscribe(data => {
        this.localeStringResources = data;
        this.filteredLocaleStringResources = data;
      });
  }

  filter() {
    this.filteredLocaleStringResources = this.localeStringResources.filter(resource => {
      const nameMatch = resource.resourceName.toLowerCase().includes(this.filterResourceName.toLowerCase());
      const valueMatch = resource.resourceValue.toLowerCase().includes(this.filterResourceValue.toLowerCase());
      const languageMatch = +this.selectedLanguageId === 0 || resource.languageId === +this.selectedLanguageId;

      return nameMatch && valueMatch && languageMatch;
    });
  }

  clearFilters() {
    this.filterResourceName = "";
    this.filterResourceValue = "";
    this.selectedLanguageId = 0;
    this.filteredLocaleStringResources = this.localeStringResources;
  }

  getLanguageNameById(languageId: number): string {
    const language = this.languages.find(lang => lang.value === languageId);
    return language ? language.label : "";
  }

  openEditDialog(resource: LocaleStringResource, allLanguages: boolean) {
    this.mainForm = this.fb.group({});

    this.filteredLanguages = allLanguages ? this.languages : [this.languages.find(lang => lang.value === resource.languageId)];

    this.filteredLanguages.forEach(language => {
      const localizedResource = this.localeStringResources.find(res => res.resourceName === resource.resourceName && res.languageId === language.value);
      const controlValue = localizedResource ? localizedResource.resourceValue : "";
      this.mainForm.addControl(language.value, new UntypedFormControl(controlValue));
    });

    this.showEditDialog = true;
  }

  saveChanges() {
    // Gem ændringerne og luk dialogen
    this.showEditDialog = false;
  }

  cancelChanges() {
    // Annuller ændringerne og luk dialogen
    this.showEditDialog = false;
  }

  filterResourceName = "";
  filterResourceValue = "";
}
