import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "@globals/services/user.service";
import { RouteNames } from "@shared/classes/RouteNames";
import { UserRoleEnum } from "@shared/interfaces-and-enums/UserRoleEnum";
import { of } from "rxjs";
import { map, take } from "rxjs/operators";

/**
 * Guarding routes against existence of roles on current user. Required roles must be passed as route data
 * to the route configuration. Access is denied when required role isn't present on user.
 * When access is denied, this guard navigates back to the default page that is shown when logged in
 */
@Injectable({
  providedIn: "root"
})
export class UserRoleGuard {
  constructor(
    private userService: UserService,
    private router: Router
  ) {}

  public canActivate(roles: Array<UserRoleEnum>) {
    return this.checkUserHasRole(roles);
  }

  public canMatch(roles: Array<UserRoleEnum>) {
    return this.checkUserHasRole(roles).pipe(
      map(hasRole => {
        if (!hasRole) this.router.parseUrl("/");
        return true;
      })
    );
  }

  private checkUserHasRole(roleNames: Array<UserRoleEnum>) {
    // Check for login is done in AppCanActivateGuard
    if (!this.userService.userHasLoggedIn()) return of(true);
    return this.userService.state$.pipe(
      //filter(s => s.userRoles.length > 0),
      take(1),
      map(() => {
        // Superadmin have super powers and can do it all!
        if (this.userService.doesUserHaveRole(UserRoleEnum.SuperAdmin)) return of(true);

        for (let i = 0; i < roleNames.length; i++) {
          if (!this.userService.doesUserHaveRole(roleNames[i])) {
            this.router.navigate([RouteNames.noAccess]);
            return false;
          }
        }
        return true;
      })
    );
  }
}
