import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { BiSpinnerComponent } from "./bi-spinner.component";

@NgModule({
  imports: [CommonModule, FormsModule, ProgressSpinnerModule],
  declarations: [BiSpinnerComponent],
  exports: [BiSpinnerComponent]
})
export class BiSpinnerModule {}
